.embeddedVideoHere {
    position: relative;
    line-height: 1.5rem;
}

.video {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16/9;
    flex-basis: 50%;
    max-height: 508px;
}

.title1 {
    margin: 0;
    position: relative;
    color: var(--charcoal-500, #7E7E7E);
    font-size: 49px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title,
.title1,
.title2 {
    align-self: stretch;
    line-height: 26px;
}

.title2 {
    margin: 0;
    position: relative;
    color: var(--primary-500, #7E7E7E);
    font-size: 49px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title {
    display: flex;
    flex-direction: column;

}

.whereverYouAre {
    align-self: stretch;
    position: relative;
    font-size: var(--button-size);
    line-height: 1.5rem;
    color: var(--charcoal-500);
}

.content {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.buttons {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--xl2);
}

.buttons {
    display: flex;
    max-width: 100%;
}

.leftColumn,
.mapBanner {
    display: flex;
    max-width: 100%;
}

.buttonsContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.leftColumn {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    gap: var(--xl2);
    text-align: left;
    font-size: var(--h2-size);
    color: var(--charcoal-300);
    flex-basis: 50%;
}

.mapBanner {
    width: 1440px;
    background: var(--gradient-ultralight, linear-gradient(258deg, rgba(242, 250, 250, 0.20) 3.34%, rgba(204, 236, 235, 0.20) 96.66%));
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--2xl, 64px);
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: var(--button-size);
    color: var(--white);
    font-family: var(--standard-font);
    width: 100%;
    justify-content: center;
    line-height: normal;
    letter-spacing: normal;
    max-width: 1440px;
    margin: var(--xl) auto;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
}

.backgroundMedia {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (max-width: 1439px) {
    .title1 {
        line-height: 39px;
    }

    .title1,
    .title2 {
        font-size: var(--font-size-20xl);
    }
    .mapBanner {
        gap: 1.5rem;
        margin: 0;
    }
    .leftColumn{
        text-align: center;
    }
    .buttonsContainer,
    .buttons
    {
        width: 100%;
    }
    .buttons{
        justify-content: center;
    }

    .mapBanner {
        padding: 0 var(--base);
    }
}

@media screen and (max-width: 1200px) {
    .mapBanner {
        margin: var(--xl2) 0;
        padding: var(--xl2);
        flex-direction: column;
    }

    .leftColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--md);
    }
}

@media screen and (max-width: 750px) {
    .video {
        min-width: 100%;
    }

    .buttons {
        flex-wrap: wrap;
        gap: var(--base, 1rem);
    }

    .mapBanner {
        gap: var(--md);
    }

    .buttonsContainer {
        gap: var(--base, 1rem);
    }
}

@media screen and (max-width: 640px) {
    .video {
        padding-top: var(--padding-131xl);
        padding-bottom: var(--padding-131xl);
        padding: 0;
        align-items: center;
        justify-content: center;
    }
    .content{
        gap: var(--gap-2xs);
        padding: 0;
    }    
    .title1,
    .title2 {
        font-size: var(--h3-size);
        text-align: center;
        height: 3rem;
        line-height: 3rem;
    }

    .whereverYouAre{
        line-height: 1.5rem;
        text-align: center;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: var(--base, 1rem);
    }

    .leftColumn {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    .mapBanner {
        gap: 1.5rem;
        padding: var(--base);
        margin: var(--xl2) 0;
    }
}
