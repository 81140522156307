/* Card Container Styles */
.cardContainer {
    position: relative;
    display: flex;
    width: 359px;
    height: 359px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-color: var(--charcoal-500, #37353C);
    font-size: var(--h5-size);
    overflow: hidden;
    cursor: pointer;
    flex-wrap: wrap;
    text-decoration: none;
}

.cardContainer.withBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    transition: background-color 0.3s ease-in-out; /* Optional transition for overlay */
    z-index: 1; /* Ensure overlay is above the background image */
}

    .cardContainer.withBackground.hasOverlay::before {
        background-color: var(--image-overlay); /* Light grey overlay */
    }

.cardContainer.withOutBackground:hover {
    background: var(--gradient-charcoal, linear-gradient(224deg, #37353C 4.12%, #000 95.88%));
}

.cardContainer:hover {
    box-shadow: 0px 24px 60px 0px rgba(0, 0, 0, 0.20);
}

.cardContainer.withBackground:hover::before {
    background-color: var(--image-overlay-dark); /* Darken overlay on hover, if desired */
}

.linkWrapper {
    text-decoration: none;
}
/* Title and Description Styles */
.title {
    padding: 17.125rem 2rem 2rem 2rem;
    text-align: center;
    width: 100%;
    transition: opacity 0.3s ease;
    z-index: 1;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 96% */
}

.title2 {
    padding: 2.875rem 3rem 1.25rem 3rem;
    text-align: center;
    width: auto;
    transition: opacity 0.3s ease;
    z-index: 1;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 96% */
}

.description {
    font-size: var(--paragraphs-size);
    padding: 0 1.3rem;
    width: auto;
    text-align: left;
    font-weight: 400;
    line-height: 1.5rem;
    z-index: 1;
    color: var(--white, #FFF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

/* Small Square Card Styles */
.smallSquareCard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    pointer-events: none;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.cardContainer:hover .title {
    opacity: 0;
}

.cardContainer:hover .smallSquareCard {
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}

/* Link Styles */
.link {
    color: var(--primary-500);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
    display: block;
    text-align: left;
    padding: 1.3rem;
}

    .link:hover {
        text-decoration: underline;
        text-underline-offset: 3px;
        color: var(--primary-500);
    }

    .link::after {
        content: " >";
    }


@media screen and (max-width: 1200px) {
    .title {
        padding: 17rem 1.5rem 2.875rem 1.5rem;
        display: none;
    }

    .title2 {
        padding: 1.25rem 1.5rem;
    }

    .description {
        text-align: center;
    }

    .smallSquareCard {
        opacity: 1;
        justify-content: center;
        transform: translateY(0);
    }

    .cardContainer.withBackground::before {
        background-color: var(--image-overlay-dark) !important;
    }

    .link {
        text-align: center;
    }

}

@media screen and (max-width: 640px) {
    .mobileCentered {
        margin: 0 auto;
    }
}