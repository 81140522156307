.outerWrapper {
    max-width: 1440px; /* Ensure it is responsive */
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
    margin-top: 2.5rem;
    flex-direction: column;
}

.frameParent {
    flex-wrap: wrap; /* Allow the content to wrap */
    justify-content: flex-start; /* Align items to the start of the row */
    gap: var(--gap-17xl);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.moduleWrapper {
    display: flex;
    gap: 2.5rem;
    margin-top: 2.5rem;
    padding-bottom: 4rem;
}

.slide {
    flex: 1 1 328px; /* Each slide takes up at least 300px */
    max-width: 358px; /* Prevents slides from exceeding container width */
}

.filters {
    text-decoration: none;
    width: 96.7px;
    position: absolute;
    margin: 0 !important;
    top: calc(50% - 9.9px);
    right: -16.4px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
    color: inherit;
    display: flex;
    align-items: center;
    z-index: 1;
}

.clearAllFilters {
    text-decoration: underline;
    align-self: stretch;
    position: relative;
    letter-spacing: 0.02em;
    font-weight: 500;
    color: inherit;
}

.locationSelectOptions input[type="checkbox"],
.locationSelectOptionsIndent input[type="checkbox"] {
    display: none;
}


    /* Hide the default checkbox */
.locationSelectOptionsIndent input[type="checkbox"] {
    display: none;
}

    /* Style the custom checkbox container */
.locationSelectOptionsIndent {
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* The custom checkbox (box with rounded corners) */
.locationSelectOptionsIndent .selectBoxCheck {
    border: 0.5px solid var(--primary-500, #2273BA);
    width: 1rem;
    height: 1rem;
    display: inline-block;
    position: relative;
    transition: background-color 0.3s;
    background: white;
}

/* The checkmark (hidden by default) */
    .locationSelectOptionsIndent .selectBoxCheck::after {
        content: "";
        position: absolute;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: none;
        border-radius: 1px;
    }

/* When the checkbox is checked, show the checkmark */
.locationSelectOptionsIndent input[type="checkbox"]:checked + .selectBoxCheck {
    background-color: var(--brand-primary-500);
    border-color: var(--brand-primary-500);
}

.locationSelectOptionsIndent input[type="checkbox"]:checked + .selectBoxCheck::after {
    display: block; /* Show the checkmark */
}

.locationsSelectWrapper {
    overflow: hidden;
    transition: max-height 0.3s ease; /* Smooth transition */
    max-height: 350px; /* Collapsed state */
}

.subLocationsWrapper {
    overflow: hidden;
    transition: max-height 0.3s ease; /* Smooth transition */
    max-height: 0; /* Collapsed state */
}

/* Styles for the plus sign */
.plus::before {
    content: '+';
    color: white; /* Customize the color */
}

/* Styles for the minus sign */
.minus::before {
    content: '-';
    color: white; /* Customize the color */
}

.selectBoxExpand {
    box-sizing: border-box;
    display: flex;
    width: 1rem;
    height: 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    background: var(--primary-500);
    border: 0.5px solid var(--primary-500, #2273BA);
    cursor: pointer;
    text-align: center;
    user-select: none;
    transition: transform 0.3s;
}


.locationSelectOptions {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-5xs) 0 var(--padding-5xs) 0;
    gap: var(--gap-5xs);
}

.continent {
    color: var(--charcoal-500, #37353C);
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.continent::after {
    content: ''; /* Creates the underline */
    display: block;
    width: 11.46719rem; /* Specific width */
    height: 0.01rem; /* Specific height */
    background: var(--charcoal-200, #7E7E7E);
    margin-top: 0.5rem; /* Creates the gap of 0.5rem between the title and underline */
}

.country {
    color: var(--charcoal-500, #37353C);
    font-family: Roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 2.25rem;
    align-self: stretch;
    display: flex;
    align-items: center;
}

.locationFilter {
    height: 529px;
    flex: 1;
    padding: 0;
    box-sizing: border-box;
}

.resultsWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--sm);
    
    flex-wrap: wrap;
    justify-content: space-between; /* Distribute slides evenly */
    margin-right: 4rem;
    margin-bottom: 4rem;;
    flex-wrap: wrap;
    width: 100%;
}

.resultsWrapper .resultsFiltered {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sm);
}

.resultsWrapper .resultsFilteredPagination {
    margin: auto;
}

.scroll {
    top: 53.8px;
    background-color: var(--light-gray-background);
    border: 1px solid rgba(196, 196, 196, 0.5);
    box-sizing: border-box;
    height: 257.7px;
}



.resultsGroup {
    flex: 1 1 205px; /* Flex-grow: 1, Flex-shrink: 1, and min-width of 300px */
    box-sizing: border-box; /* Ensure padding and borders don't affect width */
    max-width: calc(25% - var(--gap)); /* Three items per row */
    margin-bottom: 1rem; /* Add some space between wrapped rows */
}

.imageIcon {
    max-width: 1020px;
}

.location {
    align-self: stretch;
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 25.6px;
    color: var(--primary-500, #2273BA);
    font-family: Roboto;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}

.resourceWrapper {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    padding-top: 4rem;
}

@media screen and (max-width: 1024px) {
    .resourceWrapper {
        flex-direction: column;
        gap: 0;
    }
    .resultsFiltered {
        justify-content: center;
    }
    .root {
        width: auto;
    }
}

@media screen and (max-width: 768px) {
    .resultsWrapper {
        flex-direction: column; /* Stack the elements vertically */
    }
}



@media screen and (max-width: 1000px) {
    .regionNorthAmerica {
        padding-bottom: 164px;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 725px) {
    .regionNorthAmerica {
        gap: 35px;
        padding-bottom: 107px;
        box-sizing: border-box;
    }

    .root {
        gap: var(--gap-lg);
    }

    .slide {
        flex: 1 1 100%; /* Each slide takes up full width on small screens */
    }
}

@media screen and (max-width: 450px) {
    .africa,
    .asia,
    .europe,
    .northAmerica {
        font-size: var(--font-size-base);
    }

    .regionNorthAmerica {
        gap: var(--gap-lg);
    }
}

