/* Typography Styles */
.title {
    font-family: var(--standard-font); /* Uses standard font if defined */
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
}

.description {
    font-family: var(--standard-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-align: left;
}

/* Main Content Layout */
.content {
    width: 887px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--xs);
}

/* Shared Styles for Flex Containers */
.container,
.snipe,
.button {
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.container {
    flex-direction: row;
    max-width: 1440px;
    width: 100%;
    justify-content: space-between;
    padding: var(--md) 0;
    margin: 0 auto;
    gap: var(--xl);
    position: relative;
    overflow: hidden;
    align-self: stretch;
}

.snipe {
    width: 100%;
    background-color: var(--charcoal-500, #37353C);
    border-top: 0.5px solid var(--charcoal-300);
    justify-content: center;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--h4-size);
    color: var(--white);
    font-family: var(--standard-font);
    padding-left: var(--md);
    padding-right: var(--md);
}

/* Link inside .snipe and .container */
.snipe .container a {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
}

/* Responsive Adjustments */
@media screen and (max-width: 1350px) {
    .container {
        flex-wrap: wrap;
        gap: var(--base);
        justify-content: center;
    }

    .title {
        font-size: var(--h5-size);
        text-align: center;
    }

    .description {
        text-align: center;
    }
}

@media screen and (max-width: 450px) {
    .title {
        font-size: var(--h5-size);
        text-align: center;
    }

    .description {
        text-align: center;
    }
}
