.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--md);
    justify-content: center;
    align-items: center;
    max-width: 1440px;
    margin: 120px auto;
}

.eyebrow {
    margin: 0;
    color: var(--charcoal-500);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}

.title {
    color: var(--charcoal-500);
    margin: 0;
    text-align: center;
    font-size: 2.4375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem; /* 123.077% */
}

.icon img {
    max-width: 48px;
    max-height: 48px;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1200px) {
    .wrapper {
        margin: var(--xl2);
    }
}

@media screen and (max-width: 640px) {
    .wrapper {
        margin: var(--xl2) var(--base);
    }
}
