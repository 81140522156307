.environmentalIcon {
    height: 50px;
    width: 50px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.environmentalWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 var(--padding-xl) 0 21px;
}

.title {
    text-decoration: none;
    position: relative;
    line-height: 24px;
    color: inherit;
    display: inline-block;
    min-width: 111px;
}

.titleWrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 var(--padding-xl);
}

.description {
    align-self: stretch;
    position: relative;
    font-size: var(--small-size);
    line-height: 20px;
}

.genericSmallCard {
    width: 285.3px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: var(--base);
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: var(--h5-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}
