/* General Styles */

.subtitle {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    max-width: 75%;
    text-align-last: center;
}

.cardTeamCarouselWrapper .subtitle {
    text-align-last: left;
}

.titleAlt, .title {
    position: relative;
    line-height: 48px;
    font-weight: 600;
}

.title {
    margin: 0;
    font-size: inherit;
    font-family: var(--standard-font);
}

.titleAlt {
    text-decoration: none;
    color: var(--primary-500);
}

.paginationWrap {
    display: flex;
}

.titleWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--sm);
    text-align: center;
    font-size: var(--h3-size);
    max-width: 100%;
}

.cards, .content, .navigation, .verticalCarousel {
    display: flex;
    box-sizing: border-box;
    gap: var(--lg);
    justify-content: space-between;
}

.content {
    flex-direction: column;
    align-items: center;
    gap: var(--md);
    text-align: left;
    font-size: var(--button-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}

.cards {
    height: 585px;
    align-items: flex-start;
    gap: var(--xl1);
    flex-shrink: 0;
}

.viewAll {
    position: relative;
    font-size: var(--button-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--standard-font);
    color: var(--white);
    text-align: center;
    display: inline-block;
    min-width: 125px;
}

.contentGroup {
    flex-direction: row;
    gap: 6px;
}

.button {
    cursor: pointer;
    border: 1px solid var(--primary-500);
    padding: 9px 56px;
    background-color: var(--primary-500);
    border-radius: var(--md);
    min-width: 250px;
    flex-direction: column;
}

.prevButton, .nextButton {
    width: 46px;
    height: 46px;
    border-radius: var(--br-5xl);
    border: 1px solid var(--charcoal-500);
    padding: var(--padding-sm-8) var(--padding-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out; /* Smooth transition for color change */
    background: white;
}

.prevButton:hover, .nextButton:hover {
    background: var(--charcoal-500);
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    --chevron-color: white; /* Change color to white on hover */
}

.pagination {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--base);
}

.contentWrapper {
    display: flex;
    align-items: flex-end;
    gap: var(--xs);
}

.contentWrapper .textContainer {
    display: flex;
    flex-direction: column;
    gap: var(--xs);
}

.contentCardTeamWrapper {
    text-align: start;
}

.contentCardTeamWrapper .textContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: var(--xs);
}

.textContainer {
    align-items: center;
    gap: var(--xs);
}

.contentCardTeamWrapper .textContainer .titleWrapper {
    justify-content: center;
}

.verticalCarousel {
    flex-direction: column;
    line-height: normal;
    letter-spacing: normal;
    max-width: 1440px;
    margin: 0 auto;
    align-self: stretch;
    gap: var(--lg);
    position: relative;
    overflow: hidden;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2.5rem;
    padding-top: 4rem;
}

.swiper {
    width: 100%;
    overflow: visible;
}

.slide {
    display: flex;
    justify-content: left;
    align-items: center;
}

.slide:last-child {
    margin-right: 0;
}

.centeredNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigationWrapper {
    display: none;
    gap: 20px;
}

.showNavigation{
    display: flex !important;
}

.hideNavigation {
    display: none !important;
}

.cardTeamCarouselWrapper .content{
    align-items: start;
    gap: 1rem;
}
.cardTeamCarouselWrapper .navigation{
    align-self: end;
    margin-right: var(--2xl);
}
.cardTeamCarouselWrapper .cards{
    align-items: start;
    height: 100%;
}
.cardTeamCarouselWrapper .slide{
    width: 17.1875rem;
    height: 25rem;
}
.cardTeamCarouselWrapper .verticalCarousel{
    padding: 2.5rem 0px 2.5rem 4rem;
    gap: 2.5rem;
}
.cardTeamCarouselWrapper .ourTeamCtaWrapper{
    display: flex;
    justify-content: end;
    width: calc(100% - var(--2xl));
}
.eyebrowWrapper{
    font-size: var(--paragraphs-size);
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1em;
    margin: 0;
}

    /* Media Queries */

    @media screen and (max-width: 1200px) {

        .navigation {
            flex-direction: column-reverse; /* Stack the button and navigation vertically */
            align-items: flex-start; /* Align items to the start (left) */
        }

        .hideNavigation {
            display: flex;
        }

        .centeredNavigation {
            display: flex;
            align-items: initial;
            flex-direction: initial;
        }

        .cardTeamCarouselWrapper .ourTeamCtaWrapper {
            display: block;
            width: 100%;
            margin-top: var(--md);
        }

        .contentCardTeamWrapper .subtitle {
            max-width: 100%;
        }

        .cardTeamCarouselWrapper .navigation{
            align-self: flex-start;
            margin-right: 0px;
        }

        .cardTeamCarouselWrapper .verticalCarousel {
            padding: var(--md);
            gap: 2.5rem;
        }

        .cardTeamCarouselWrapper .titleWrapper {
            align-items: start;
        }
    }

    @media screen and (max-width: 1024px) {
        .excellence, .oneStandard {
            font-size: var(--font-size-12xl);
            line-height: 38px;
        }

        .contentWrapper {
            flex-direction: column;
            gap: var(--md);
        }

        .verticalCarousel {
            gap: var(--lg);
            padding: var(--base);
        }

        .eyebrowWrapper {
            color: var(--charcoal-500, #37353C);
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 125% */
            letter-spacing: 0.1rem;
            text-transform: uppercase;
        }

        .title {
            color: var(--charcoal-500, #37353C);
            text-align: center;
            font-size: var(--font-size-12xl);
            font-style: normal;
            font-weight: 600;
            line-height: 1.875rem; /* 96.774% */
        }

        .titleAlt {
            color: var(--primary-500, #00A19B);
            text-align: center;
            font-size: var(--font-size-12xl);
            font-style: normal;
            font-weight: 600;
            line-height: 1.875rem; /* 96.774% */
        }

        .titleWrapper {
            flex-direction: column;
            align-items: center;
        }

        .navigationWrapper{
            display: flex;
        }
    }

    @media screen and (max-width: 675px) {
        .title {
            flex-wrap: wrap;
        }

        .cards {
            gap: var(--xl1);
            height: 425px;
        }

        .navigation {
            flex-wrap: wrap;
            gap: var(--lg);
        }

        .centeredNavigation .buttonWrapper {
            padding-right: 0px;
        }
    }

    @media screen and (max-width: 640px) {
        .cards:not(.cards.isCardTeam) {
            height: 385px;
        }
    }

@media screen and (max-width: 550px) {
    .buttonWrapper {
        order: 0;
    }
}

@media screen and (max-width: 450px) {
    .excellence, .oneStandard {
        font-size: var(--font-size-4xl);
        line-height: 29px;
    }
}
