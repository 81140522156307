.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    cursor: auto;
    background: var(--image-overlay-dark);
}


.container {
    position: fixed;
    background-color: var(--white);
    max-width: 1440px;
    width: 100%;
    padding: var(--base);
    box-shadow: var(--shadow-component-dark);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    box-sizing: border-box;
    max-height: 40rem;
    overflow: auto;
}

.closeButtonWrapper {
    align-self: flex-end;
    margin-bottom: var(--base);
}

.closeButtonWrapper > img {
    width: 1.5rem;
    height: 1.5rem;
}

.closeButtonWrapper:hover {
    cursor: pointer;
}

@media screen and (max-width: 640px) {
    .container {
        padding: var(--base);
        max-height: 70vh;
        width: calc(100% - var(--2xl) - var(--2xl));
        left: calc(50% + var(--2xl));
        transform: translate(calc(-50% - var(--2xl)), -50%);
    }

    .closeButtonWrapper {
        top: var(--md);
        right: var(--md);
    }
}
