/* Layout and Wrapper Styles */
.aboutBanner {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.root {
    display: flex;
    flex-direction: column;
    padding: 0 5px 0 0;
    gap: 2rem;
    margin-left: 4rem;
}

.taxonomySelect {
    border-top: 1px solid var(--charcoal-300); /* Adds a 1-pixel line above */
    padding-top: 10px; /* Optional: Add some padding if needed */
}
/* Filter Section */
.locationsOptions {
    display: flex;
    flex-direction: column;
    gap: 13px;
    align-items: flex-start;
    color: var(--charcoal-500);
    font-size: var(--font-size-sm);
    font-family: var(--font-roboto);
    width: 11.5rem;
}

/* For WebKit browsers (Chrome, Safari, etc.) */
.locationFilter::-webkit-scrollbar {
    width: 0.375rem; /* Change the width to make it slimmer */
    margin-left: 5px;
}



.locationFilter::-webkit-scrollbar-thumb {
    background-color: #C4C4C4; /* Color of the scrollbar handle */
    border-radius: 0.625rem; /* Rounded corners for a smoother look */
}

    .locationFilter::-webkit-scrollbar-thumb:hover {
        background-color: #C4C4C4; /* Color when hovering over the scrollbar */
    }

/* Optional: Style the scrollbar track */
.locationFilter::-webkit-scrollbar-track {
    background: transparent; /* The scrollbar track (default: transparent) */
}

.categoryOptions,
.clearAllFiltersButton {
    display: flex;
    align-items: flex-start;
}

.categoryOptions {
    width: 11.5rem;
}

.category {
    display: flex;
    align-items: center;
    position: relative;
    width: 6rem;
}

.filters {
    text-decoration: none;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--charcoal-500, #37353C);
    font-family: roboto;
    font-size: 0.875rem;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.0875rem;
}

.clearAllFilters {
    color: var(--primary-500, #2273BA);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.015rem;
    text-decoration-line: underline;
    cursor: pointer;
}

.locationsSelectWrapper,
.subLocationWrapper {
    overflow: hidden;
    transition: max-height 0.3s ease;
}

/* Location, Certification, and Metal Filter Styles */
.locationFilter,
.certificationSelect,
.taxonomySelect {
    display: flex;
    flex-direction: column;
    gap: var(--gap-5xs);
    padding: 0 var(--padding-8xl) 13.1px 0;
    padding-top:20px;
    padding-right:0px;
    width: 11.5rem;
}

.filterHeaderWrapper {
    display: flex;
}

.toggle {
    padding-left:.5rem;
    cursor: pointer;
}

.optionsWrapper.expanded {
    max-height: 500px; /* Adjust this value as needed */
}

.locationSelectOptions,
.locationSelectOptionsIndent {
    display: flex;
    align-items: center;
    gap: var(--gap-5xs);
    padding: var(--padding-5xs) 0;
    cursor: pointer;
}

.locationSelectOptionsIndent {
    padding-left: 1.5rem;
}
    .selectBoxCheck {
    width: 1rem;
    height: 1rem;
    border: 0.5px solid var(--primary-500);
    background: white;
    position: relative;
    transition: background-color 0.3s;
}

.selectBoxCheck::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
}

.optionsWrapper input {
    display: none;
}

input[type="checkbox"]:checked + .selectBoxCheck {
    background-color: var(--primary-500);
    border-color: var(--primary-500);
}

input[type="checkbox"]:checked + .selectBoxCheck::after {
    display: block;
}

/* Expand/Collapse Styles */
.selectBoxExpand {
    display: flex;
    width: 1rem;
    height: 1rem;
    justify-content: center;
    align-items: center;
    background: var(--primary-500);
    border: 0.5px solid var(--primary-500);
    cursor: pointer;
    transition: transform 0.3s;
}

.locationFilter input {
    display: none;
}

.locationFilter {
    height: 41.125rem;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
}

.country {
    color: var(--charcoal-500, #37353C);
    font-family: "Roboto Condensed";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
}

.filterHeader {
    font-family: "Roboto";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.37313rem;
    padding-top: 2px;
}

.continent {
    color: var(--charcoal-500, #37353C);
    font-family: "Roboto Condensed";
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
}


@media (max-width: 1024px) {
    .root {
        margin-left: var(--xl2);
    }
    .locationsOptions {
        width: 100%;
    }
}
