.container {
    max-width: 1440px;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: var(--charcoal-500);
    margin: var(--xl) auto;
    box-sizing: border-box;
    gap: .75rem;
    margin-top: 6rem;
}

.smallCard a {
    color: var(--charcoal-500);
    text-decoration: none;
}

.smallCard a:hover {
    color: var(--primary-500);
    text-decoration: none;
}

.content {
    display: flex;
    flex-direction: column;
    gap: var(--md);
}

.logoImage {
    width: 64px;
    height: 64px;
}

.itemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    gap: var(--md);
}

.smallCard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: var(--base);
    justify-content: center;
    align-items: center;
}

.titleWrapper {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.title {
    margin-top: var(--2xl, 4rem);
}

.titleAlt {
    color: var(--brand-primary-500);
}

.title,
.titleAlt {
    font-weight: 600;
    font-size: 2.4375rem;
    margin: 0;
}

.icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.eyebrow {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin: 0;
}

.description {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin-top: 0;
    margin-bottom: 3rem;
}

.smallTitle {
    font-size: 1.5625rem;
    font-weight: 400;
    line-height: 1.5rem;
    margin: 0;
}

.smallDescription {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25rem;
    margin: 0;
}

.button {
    margin-top: var(--md);
}

@media screen and (max-width: 1439px) {
    .container {
        margin: var(--xl2);
        gap: var(--md);
    }
}

@media screen and (max-width: 992px) {
    .smallCard {
        flex: 1 1 100%;
    }

    .container {
        gap: var(--md);
    }
    .title,
    .titleAlt {
        font-size: var(--font-size-12xl);
        line-height: 1.875rem;
        margin: 0;
    }
    .smallCard {
        width: 100%;
        max-width: unset;
        margin-bottom: 0;
    }

    .titleIcon,
    .eyebrow,
    .description,
    .button {
        margin-bottom: 0;
    }

    .titleWrapper {
        margin-bottom: 0 !important;
        justify-content: center;
    }

    .itemContainer {
        flex-direction: column;
        gap: var(--md);
    }
}

@media screen and (max-width: 640px) {
    .container {
        margin: var(--xl2) var(--base);
    }
}
