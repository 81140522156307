.automotive,
.shortDescriptionIf {
    align-self: stretch;
    position: relative;
}

.title {
    margin: 0;
    font-weight: 600;
    font-size: 2.4375rem;
    font-style: normal;
    line-height: 3rem;
}

.description {
    font-size: var(--button-size);
    line-height: 24px;
}

.content,
.largeSquareCard {
    flex: 1;
    display: flex;
    align-items: center;
    max-width: 100%;
}

.content {
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--lg);
}

.largeSquareCard {
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    padding: var(--padding-166xl) var(--padding-44xl);
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-position: top;
    min-width: 444px;
    text-align: center;
    font-size: var(--h3-size);
    color: var(--white);
    font-family: var(--standard-font);
    background: var(--charcoal-500);
    display: flex;
    width: 42.6875rem;
    height: 34.5rem;
    padding: 0rem var(--2xl, 4rem);
    justify-content: center;
    align-items: center;
    position: relative;
    transition: box-shadow 0.3s ease-in-out;
    background-size: cover; /* Ensure the image covers the entire div */
    background-position: center;
    cursor: pointer;
    max-width: 683px;
}

.largeSquareCard.withBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--image-overlay); /* Light grey overlay */
    transition: background-color 0.3s ease-in-out; /* Optional transition for overlay */
    z-index: 1; /* Make sure overlay is above the background image */
}

.largeSquareCard.withBackground:hover::before {
    background-color: var(--image-overlay-dark); /* Darken overlay on hover, if desired */
}

.largeSquareCard:hover {
    box-shadow: var(--shadow-component-dark);
    background-size: 110%;
}

.largeSquareCard .content {
position: relative;
z-index: 2; /* Ensure the text/content is above the overlay */
}


.largeSquareCard {
    transition: box-shadow 0.3s ease-in-out;
    transition: background-size 0.3s ease-in-out; 
    position: relative;
}

    .largeSquareCard a,
    .largeSquareCard button {
        font-size: var(--button-size);
    }

    .largeSquareCard a::after,
    .largeSquareCard button::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: "";
    }

@media screen and (max-width: 1200px) {
    .title {
        font-size: var(--font-size-12xl);
        font-style: normal;
        font-weight: 600;
        line-height: 1.875rem; /* 96.774% */
    }

    .largeSquareCard {
        padding: var(--lg) var(--xl2);
        box-sizing: border-box;
    }
}

@media screen and (max-width: 675px) {
    .content {
        gap: var(--lg);
    }

    .largeSquareCard {
        box-sizing: border-box;
        min-width: 100%;
        height: 380px;
    }
}

@media screen and (max-width: 450px) {
    .content {
        gap: var(--lg);
    }

    .largeSquareCard {
        box-sizing: border-box;
        min-width: 100%;
    }
}



