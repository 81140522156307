.container {
    position: relative;
    max-width: 1440px;
    margin: var(--xl) auto;
}

.wrapper.noBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.navigation {
    display: flex;
    gap: var(--base);
}

.pagination {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--base);
    min-width: 0 !important;
}

.wrapper.noBackground h4 {
    color: var(--charcoal-500);
}

.wrapper.noBackground .quoteSymbol {
    color: var(--primary-500);
    text-align: center;
    font-size: var(--h1-size);
    font-style: normal;
    font-weight: 600;
    line-height: var(--h1-size);
}

.wrapper.noBackground .name {
    color: var(--primary-500);
}

.wrapper.hasBackground .quoteSymbol {
    color: var(--white);
    text-align: center;
    font-size: var(--h1-size);
    font-style: normal;
    font-weight: 600;
    line-height: var(--h1-size);
    z-index:10;
}

.wrapper.hasBackground::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background: var(--charcoal-500);
    z-index: 1;
}

.wrapper h3,
.wrapper h4,
.wrapper p,
.wrapper img {
    position: relative;
    z-index: 2;
    color: white;
    margin: 0px;
    text-align: center;
    font-style: normal;
}

.subtitle {
     margin-top: var(--md) !important;
     font-size: 16px;
     font-weight: 400;
     line-height: 24px;
}

.wrapper {
     position: relative;
     overflow: hidden;
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     display: flex;
     padding: var(--4xl, 88px) var(--5xl, 104px) calc(var(--4xl, 88px) + 45.45px + var(--md)) var(--5xl, 104px);
     flex-direction: column;
     justify-content: center;
     align-items: center;
     width: 100%;
     height: 100%;
     gap: var(--md);
}



.wrapper > img {
     margin: var(--md) 0px;
}
.wrapper > .title {
     font-size: 39px;
     font-weight: 600;
     line-height: 48px;
}
.wrapper > .quote {
     font-size: var(--font-size-12xl);
     font-weight: 500;
     line-height: 34px;
     text-align: center;
}
.wrapper > .name {
     font-size: 10px;
     font-weight: 400;
     line-height: normal;
     letter-spacing: 0.2px;
}
.wrapper > .portrait {
     height: 55px;
     width: 55px;
     border-radius: 50%;
     object-fit: cover;
     object-position: center;
}




.chevronRightLarge {
     height: 18.5px;
     width: 20px;
     position: relative;
     overflow: hidden;
     flex-shrink: 0;
}

.prevButton,
.nextButton {
     border-radius: 50%;
     background-color: transparent;
     border: 1px solid white;
     padding: 12px;
     display: flex;
     align-items: center;
     justify-content: center;
}


.slide {
     display: flex;
     justify-content: center;
     align-items: center;
}

.navigation {
     position: absolute;
     bottom: 0px;
     display: flex;
     left: 50%;
     transform: translate(-50%, 0);
     z-index: 3;
     padding-bottom: var(--4xl, 88px);
}

.avatar {
     width: 55px;
     height: 55px;
     border-radius: 50%;
}

.avatar img {
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 50%;
}

@media screen and (max-width: 1200px) {
     .container {
          margin: var(--xl2) auto;
     }
}


@media screen and (max-width: 750px) {
     .wrapper {
          padding: var(--md) var(--base) calc(var(--md) + 45.45px + var(--md)) var(--base);
     }

     .wrapper > .title {
          font-size: var(--font-size-12xl);
     }

     .wrapper > .subtitle {
          font-size: 16px;
     }

     .wrapper > .quote {
          font-size: 25px;
     }

     .navigation {
          padding-bottom: var(--md);
     }
 }