.announcementBanner {
    width: 100%;
    background-color: var(--white);
    border-top: 0.5px solid var(--white);
    border-bottom: 0.5px solid var(--white);
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center; /* Center vertically */
    justify-content: center;
    padding: var(--padding-6xs) var(--padding-xl);
    gap: var(--sm);
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: var(--button-size);
    color: var(--white);
    font-family: var(--standard-font);
}

/* Announcement Banner Styles */
.message,
.type,
.cta_announcement {
    position: relative;
    line-height: 1.5rem;
    display: inline-block;
}

.type {
    text-decoration: none;
    min-width: 7.5rem;
    color: var(--primary-500);
}
.cta_announcement{
    color: var(--primary-500);
}

.message {
    color: var(--charcoal-500, #37353C);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.message a {
    font-weight: 700;
    text-decoration-line: underline;
    color: var(--primary-500);
}

.registerNow {
    text-decoration: underline;
    line-height: 20px;
}

/* Add Flexbox Centering to <p> Tags Inside .message */
.message p {
    margin: 0; /* Remove default margins */
}

.light {

}

.dark {
    border-top: 0.5px solid var(--white, #FFF);
    border-bottom: 0.5px solid var(--white, #FFF);
    background: var(--black, #000);
    width: 1440px;
    margin: auto;
}

    .dark .message {
        color: var(--white, #FFF);
        text-align: center;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
    }

/* Media Queries */
@media screen and (max-width: 869px) {
    .announcementBanner {
        flex-wrap: wrap;
    }
}