.engagingHeadlineAboutContainer {
    flex: 1;
    position: relative;
    line-height: 48px;
    font-weight: 600;
    display: inline-block;
    max-width: 100%;
}

.imageIcon,
.title {
    align-self: stretch;
    max-width: 100%;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 var(--xl2);
    box-sizing: border-box;
    text-align: center;
    color: var(--charcoal-500);
    max-width: 69rem;
    margin: 0 auto;
    font-family: var(--standard-font);
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 123.077% */
}

.imageIcon {
    height: 452px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    background: lightgray 50% / cover no-repeat;
}

.benefitLabeledIn,
.briefDescriptionOf {
    width: 666.7px;
    position: relative;
    line-height: 24px;
    max-width: 100%;
}

.benefitLabeledIn {
    display: flex;
    align-items: center;
}

.briefDescriptionOf {
    font-size: var(--paragraphs-size);
    display: inline-block;
}

.copy {
    align-self: stretch;
    background-color: var(--charcoal-500);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--base) 20px var(--base) var(--xl1);
    box-sizing: border-box;
    gap: var(--xs);
    max-width: 100%;
}

.container,
.preview,
.servicesTabs {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.preview {
    width: 745px;
    border-radius: 24px;
    background-color: var(--white);
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-end;
    min-width: 745px;
    box-shadow: var(--shadow-component-dark);
}

.container,
.servicesTabs {
    justify-content: center;
    gap: var(--2xl, 4rem);
}

.container {
    align-self: stretch;
    flex-direction: row;
    text-align: left;
    font-size: var(--h5-size);
    color: var(--white);
    font-family: var(--standard-font);
}

.servicesTabs {
    width: 1440px;
    flex-direction: column;
    padding: var(--xl);
    box-sizing: border-box;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 auto;
}

.verticalTab {
    align-self: stretch;
}
.tab {
    flex: 1;
    position: relative;
    line-height: 2.9375rem;
    font-weight: 600;
    display: inline-block;
    max-width: 100%;
    padding: var(--padding-21xl) 0;
    font-size: 28px;
    font-style: normal;
}

.tab.active {
    color: var(--brand-primary-500);
}

.copy,
.verticalTab {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 var(--md);
    box-sizing: border-box;
    max-width: 100%;
}

.copy {
    flex-direction: column;
    padding: var(--base, 1rem) var(--xl2, 2.5rem);
}

.verticalTab {
    border-bottom: 1px solid var(--charcoal-500);
    justify-content: flex-start;
}

.verticalTab:hover {
    color: var(--brand-primary-500);
    cursor: pointer;
}

.tabsVertical {
    display: flex;
    flex-direction: column;
    max-width: 100%;
}

.benefitLabeledIn{
    line-height: 1.5rem;
}

.img,
.backgroundMedia,
.videoWrapper {
    object-fit: cover;
    height: auto;
    width: 46.6875rem;
}

.tabsVertical {
    flex: 1;
    align-items: flex-start;
    justify-content: center;
    min-width: 326px;
    max-width: 31.3125rem;
    text-align: left;
    font-size: var(--font-size-20xl);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}

.tabWrapper {
    width: 100%;
}

.mobilePreview,
.expandIcon {
    display: none;
}

@media screen and (max-width: 1050px) {
    .keyBenefit1,
    .keyBenefit11,
    .keyBenefit12 {
        font-size: var(--font-size-12xl);
        line-height: 38px;
    }
}

@media screen and (max-width: 450px) {
    .keyBenefit1,
    .keyBenefit11,
    .keyBenefit12 {
        font-size: var(--font-size-4xl);
        line-height: 29px;
    }
}

@media screen and (max-width: 1200px) {
    .title {
        padding-left: var(--xl2);
        padding-right: var(--xl2);
        box-sizing: border-box;
        font-weight: 400;
        font-size: 1.5625rem;
    }
    .tab {
        font-weight: 400;
        font-size: 1.5rem;
        padding: 1.25rem 0;
        line-height: var(--font-size-12xl);
    }
    .preview {
        display: none;
        flex: 1;
        min-width: 100%;
    }

    .tabsVertical {
        max-width: 100%;
    }
    .container {
        flex-wrap: wrap;
    }
    .engagingHeadlineAboutContainer {
        font-size: var(--font-size-12xl);
        line-height: 38px;
    }
    .servicesTabs {
        padding: var(--xl2) 0;
        justify-content: center;
        gap: 0;
    }
    .mobilePreview {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .mobilePreview .img,
    .mobilePreview .backgroundMedia,
    .videoWrapper {
        width: 100%;
        max-height: 30rem;
        object-fit: cover;
    }

    .mobilePreview .detailLabel {
        color: var(--white);
        margin-bottom: var(--xs);
        font-size: 1.5625rem;
        font-weight: 400;
        line-height: 1.625rem;
    }

    .mobilePreview .description {
        color: var(--white);
    }
    .mobilePreview .detail {
        background-color: var(--charcoal-500);
        padding: var(--base) var(--padding-21xl);
        font-size: 1rem;
        font-weight: 400;
    }
    .expandIcon {
        display: block;
    }
}

@media screen and (max-width: 750px) {
    .mobilePreview .img,
    .mobilePreview .backgroundMedia,
    .videoWrapper {
        height: 15.125rem;
    }

    .title {
        text-align: start;
        line-height: 1.5rem;
        padding: 0 1rem;
    }

    .container {
        gap: var(--xl);
    }
}
