.container {
    max-width: 1440px;
    width: 100%;
    padding-bottom: 43px;
    margin: var(--gap-13xl) auto 0;
}

.container p {
    font-family: "Roboto";
}

.sidebarContainer {
    padding-right: var(--base);
    min-width: 300px;
    padding-left: 40px;
}

.switchWrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 14px 0 18px 0;
    border-bottom: 0.25px solid #bfbebe;
}

.main {
    display: flex;
}

.filterTitleWrapper {
    display: flex;
    justify-content: space-between;
    padding: 18px 0px 12px 0px;
    align-items: center;
    border-bottom: 1px solid #bfbebe;
}

.switchWrapper > p,
.filterTitleWrapper > p {
    margin: 0;
}
.filterTitle {
    color: var(--charcoal-500);
    font-size: 14px;
    font-weight: 700;
}

.radioContainer {
    padding: 18px 0px;
    border-bottom: 0.25px solid #bfbebe;
}
.sliderWrapper {
    margin: var(--xs) 10px 0 10px;
}

.filterWrapper {
    max-height: 782px;
    width: 100%;
    overflow: auto;
    padding-right: var(--base);
}

.tableContainer {
    width: 100%;
    margin-top: 53px;
    margin-left: 50px;
    padding-right: 40px;
}

.table {
    margin-top: 43px;
}
.filterWrapper::-webkit-scrollbar {
    width: 0.375rem;
}

.filterWrapper::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 0.625rem;
}

.filterWrapper::-webkit-scrollbar-thumb:hover {
    background-color: #c4c4c4;
}

.filterWrapper::-webkit-scrollbar-track {
    background: var(--white);
    border-left: 1px solid rgba(196, 196, 196, 0.5);
    border-right: 1px solid rgba(196, 196, 196, 0.5);
}

.sliderContainer {
    padding: 18px 0 0 0;
}

.sliderContainer:last-child {
    padding-bottom: 18px;
}

.clearFilter {
    color: var(--primary-500, #2273ba);
    font-family: var(--standard-font);
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.switchTitle {
    font-size: 14px;
    color: #bfbebe;
}

.ratioSwiper {
    width: 100%;
}

.radioWrapper {
    display: flex;
    gap: var(--xs);
}

.radioTitle {
    font-size: 13px;
    pointer-events: none;
    font-family: 'Roboto Condensed';
    margin: 0
}

.radioTitleWrapper {
    display: flex;
    align-items: center;
    gap: var(--xs);
    margin-bottom: 10px;
}

.filterName {
    color: var(--charcoal-500, #37353c);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
}
.filterAlt {
    color: #bfbebe;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
}

.sliderMark {
    display: flex;
    justify-content: space-between;
    margin-top: var(--base);
}

.sliderMark > p {
    font-size: 12px;
    color: var(--charcoal-500);
    margin-bottom: 0;
    width: 33%;
}

.sliderMark > p:first-child {
    text-align: start;
}

.sliderMark > p:nth-child(2) {
    text-align: center;
}

.sliderMark > p:last-child {
    text-align: end;
}

.footerButtons {
    display: flex;
    justify-content: space-between;
}

.buttonRight {
    display: flex;
    gap: 14px;
}

.note {
    font-family: 'Helvetica';
    color: #555656;
    font-size: 11px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 16px;
}

.printTable {
    margin: 0;
}

.displayNone {
    display: none;
}

.sortIcon {
    padding-left: 2px;
}

@media screen and (max-width: 767px) {
    .main {
        flex-direction: column;
        gap: 0;
    }
    .sidebarContainer {
        padding-left: var(--base);
        max-width: 100%;
    }

    .tableContainer {
        margin-left: 0;
    }
    .footerButtons {
        flex-direction: column;
        gap: var(--base);
    }
    .tableFooter {
        margin: 0 var(--base);
    }
}
