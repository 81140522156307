.outerWrapper {
    max-width: 1440px;
    margin: 0 auto;
}

.moduleWrapper {
    padding-bottom: 2rem;
}

.mediaWrapper {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
}

.virtualTour {
    max-width: 52rem;

}

.socialWrapper {
    margin-bottom: 3rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.description {
    color: var(--black, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    display: flex;
    flex-direction: column;
    gap: var(--md)
}

.description * {
    margin: 0;
}

.readingLine {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
}

.articleHead {
    display: flex;
    justify-content: space-between;
}

.leftPanel {
    display: flex;
    flex-direction:column;
}

.line {
    width: 2.1875rem;
    height: 0.0625rem;
    background-color: var(--primary-300); /* You can change the color as needed */
    margin-right: 1rem;
}

.boldText {
    color: var(--black, #000);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 133.333% */
    padding-bottom: .5rem;
}

.normalText {
    color: var(--black, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    margin-bottom: auto;
}

.largeText {
    color: var(--black, #000);
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 96% */
    margin-top: 1rem;
    margin-bottom: .25rem;
}

.divider {
    height: 100%;
    width: 0.3px;
    position: relative;
    border-right: 0.3px solid var(--charcoal-300);
    box-sizing: border-box;
}

.iframe {
    height: 56rem;
    width: 25rem;
}

.root {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    margin-left: 2rem;
}

.taxonomyWrapper {
    border-left: 1px solid var(--charcoal-300);
    padding-left: 4.3125rem;
}

.image {
    max-width: 38.75rem;
}

.contactWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rightPanel {
    display: flex;
    gap: 1.875rem;
}

.taxonomyItem {
    color: var(--black, #000);
    font-family: "Roboto Condensed";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
}

.taxonomy {
    color: var(--black, #000);
    font-family: "Roboto Condensed";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    padding-top: 1.5rem;
}

ul.taxonomyList li {
    margin-bottom:.75rem;
}

@media (max-width: 1439px) {
    .leftPanel, .rightPanel {
        max-width: 100%; /* Full width on smaller screens */
        text-align: left; /* Align to left when stacked */
        padding: 0 2rem;
    }
    .description {
        padding: 0;
        width: 100%;
    }

    .image {
        padding: 0 2rem;
    }

    .root {
        flex-direction: row;
        gap: 1rem;
        margin: var(--xl2);
    }

    .virtualTour {
        width: auto;
    }
}

@media (max-width: 992px) {
    .contactWrapper {
        flex-direction: column; /* Stack the panels on smaller screens */
    }

    .leftPanel, .rightPanel {
        max-width: 100%; /* Full width on smaller screens */
        text-align: left; /* Align to left when stacked */
        padding: 0 2rem;
    }

    .rightPanel {
        padding-top: 2rem;
    }

    .description {
        padding: 0;
        width: 100%;
    }

    .divider {
        display: none;
    }

    .root {
        flex-direction: column;
        gap: 1rem;
        margin: var(--xl2) var(--base);
    }

    .image {
        padding: 0;
    }

    .taxonomyWrapper {
        border-left: none;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .iframe {
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .iframe {
        height: 53.5rem;
    }
}
