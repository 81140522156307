.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--md);
}

.container * {
    margin: 0;
}

.iframe {
    width: 19rem;
    height: 48rem;
}

.wrapper {
    max-width: 1320px;
    margin: var(--xl) auto;
    display: flex;
    flex-direction: row;
    gap: 8rem;
}

.divider {
    background: var(--Light-Charcoal, #7E7E7E);
    width: 0.25px;
    height: auto;
}

@media screen and (max-width: 1439px) {
    .wrapper {
        margin: var(--xl2);
    }
}

@media screen and (max-width: 992px) {
    .wrapper {
        flex-direction: column;
        gap: 4rem;
    }

    .wrapper h4{
        text-align: center;
    }

    .divider{
        display: none;
    }

    .iframe {
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .wrapper{
        margin: var(--xl2) var(--base);
        gap: var(--base);
    }
}