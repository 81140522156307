.container {
    display: flex;
}

.image {
    margin-top: calc(var(--2xl) + 3rem);
    width: 20.35919rem;
    height: 25rem;
    max-width: 20.35919rem;
    max-height: 25rem;
    object-fit: cover;
}

.title {
    color: var(--charcoal-500);
    margin-top: var(--2xl);
    font-size: 2.4375rem;
    font-weight: 600;
}

.jobTitle {
    color: var(--charcoal-500);
    margin: var(--md) 0px;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}

.description {
    color: var(--charcoal-500);
    font-weight: 400;
    font-size: 1rem;
}

.textWrapper {
    margin-right: var(--2xl);
}

.imageMobile {
    display: none;
}
.image {
    display: block;
} 

@media screen and (max-width: 1000px) {
    .imageMobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: auto;
        height: auto;
        max-height: 14.375rem;
        max-width: 14.4rem;
        object-fit: cover;
        margin-bottom: var(--md);
    }
    .image {
        display: none;
        
    } 
    
    .title {
        font-weight: 500;
        font-size: var(--font-size-12xl);
        margin: 0px 40px 0px 0px;
        text-transform: uppercase;
    }
    
    .jobTitle {
        margin: var(--md) 0px;
        font-size: 1rem;
        font-weight: 700;
    }
    
    .description {
        font-weight: 400;
        font-size: 1rem;
    }
    .textWrapper {
        margin-right: 0px;
    }
}