.wrapper {
    margin: var(--xl2) auto;
    max-width: 1440px;
    background: linear-gradient(50.27deg, #000000 8.57%, #37353C 94.33%) no-repeat center / cover, transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-size: cover;
    background-position: center;
}

.content a {
    align-content:center;
}
.content {
    color: var(--white);
    gap: var(--2xl);
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: flex-start;
    letter-spacing: normal;
    line-height: normal;
    gap: var(--2xl, 4rem);
    margin: var(--3xl) var(--2xl);
    padding: 0 var(--2xl);
    z-index: 2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--image-overlay-light);
    transition: background-color 0.3s ease-in-out;
    z-index: 1;
}

.content .text {
    display: flex;
    flex-direction: column;
    gap: var(--base);
    max-width: 49.375rem;
}

.content .title {
    font-size: 49px;
    font-weight: 600;
    line-height: 49px;
    margin: 0;
}

.content .subtitle {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
}

@media screen and (max-width: 1200px) {

    .content {
        margin: var(--md) var(--base);
    }

    .content {
        flex-direction: column;
        text-align: center;
        align-items: center;
        left: 0;
        padding: 0;
        gap: var(--base);
    }
}

@media screen and (max-width: 767px) {
    .content .title {
        font-size: var(--font-size-12xl);
        font-weight: 500;
        line-height: 30px;
        margin: 0;
    }
}