.outerWrapper {
    max-width: 1440px;
    margin: 2.5rem auto 0;
}

.moduleWrapper {
    max-width: 61.25rem;
    margin: 0 auto;
}

.mediaWrapper {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
}

.virtualTour {
    width: 620px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 36px;
    font-size: 12px;
    color: var(--color-black);
    font-family: var(--font-inter);
    
}

.description {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.leftPanel {
    display: flex;
    flex-direction:column;
}

.boldText {
    color: var(--black, #000);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem; /* 133.333% */
    padding-bottom: .5rem;
}

.normalText {
    color: var(--black, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    margin-bottom: auto;
}

.largeText {
    color: var(--black, #000);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    padding-bottom: .5rem;
}

.divider {
    height: 100%;
    width: 0.3px;
    position: relative;
    border-right: 0.3px solid var(--charcoal-300);
    box-sizing: border-box;
}


.root {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 0 48.5px;
    box-sizing: border-box;
    position: relative;
    gap: 5.25rem;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--eyebrows-size);
    color: var(--color-black);
    font-family: var(--font-roboto-condensed);
    margin-top: 2rem;
}

.taxonomyWrapper {
    border-left: 1px solid var(--charcoal-300);
    padding-left: 4.3125rem;
}

.image {
    max-width: 38.75rem;
}

.contactWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rightPanel {
    display: flex;
    gap: 1.875rem;
}

.taxonomyItem {
    color: var(--black, #000);
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.taxonomy {
    color: var(--black, #000);
    font-family: "Roboto Condensed";
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    padding-top: 1.5rem;
}

ul.taxonomyList li {
    margin-bottom:.75rem;
}

.wistia{
    height: 100%;
    width: 100%;
}

@media (max-width: 1055px) {
    .leftPanel, .rightPanel {
        max-width: 100%; /* Full width on smaller screens */
        text-align: left; /* Align to left when stacked */
        padding: 0 2rem;
    }
    .description, .wistia {
        padding: 0 2rem;
        width: 70%;
    }

    .image {
        padding: 0 2rem;
    }

    .root {
        flex-direction: column;
        gap: 1rem;
    }
}

@media (max-width: 768px) {
    .contactWrapper {
        flex-direction: column; /* Stack the panels on smaller screens */
    }

    .leftPanel, .rightPanel {
        max-width: 100%; /* Full width on smaller screens */
        text-align: left; /* Align to left when stacked */
        padding: 0 2rem;
    }

    .rightPanel {
        padding-top: 2rem;
    }

    .description, .wistia {
        padding: 0 2rem;
        width: 70%;
    }

    .divider {
        display: none;
    }

    .root {
        flex-direction: column;
        gap: 1rem;
    }

    .image {
        padding: 0;
    }
}
