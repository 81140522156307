.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: var(--xl);
    padding: var(--md) var(--xl);
}

.heading {
    font-size: 25px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: var(--charcoal-500);
}

.presenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--gap-base);
    width: 200px;
}

.information {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-2xs)
}

.avatar {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.avatar-hidden {
    background-color: var(--charcoal-300);

}

.avatar-loaded {
    background-color: transparent;
}

.name, .title, .company {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
}

.name {
    font-weight: 700;
    color: var(--charcoal-500);
}

.title, .company {
    font-weight: 400;
    color: var(--charcoal-500);
}

@media screen and (max-width: 1200px) {
    .wrapper {
        gap: var(--md);
        padding: var(--md) var(--base);
    }
}
