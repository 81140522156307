html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    line-height: normal;
    font-family: "proxima-nova",sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
}

div, p, h1, h2, h3, h4, h5, span, a, button {
    font-family: "proxima-nova",sans-serif;
    font-weight: 400;
}



:root {
    /* fonts */
    --standard-font: "proxima-nova",sans-serif;
    --paragraphs: "proxima-nova",sans-serif;
    --button: "proxima-nova",sans-serif;
    /* font sizes */
    --extra-small-size: 0.625rem;
    --small-size: 0.813rem;
    --font-size-xs: 0.75rem;
    --button-size: 1rem;
    --h1-size: 3.825rem;
    --h2-size: 3.063rem;
    --h3-size: 2.438rem;
    --h5-size: 1.563rem;
    --h4-size: 1.4rem;
    --font-size-18xl: 2.313rem;
    --breadcrumbs-size: 0.75rem;
    --paragraphs-size: 1rem;
    --font-size-4xl: 1.438rem;
    --font-size-12xl: 1.9375rem;
    --sub-cta-content: 1.5rem;
    /* Colors */
    --white: #fff;
    --charcoal-500: #37353c;
    --charcoal-200: #c0bebe;
    --charcoal-300: #7e7e7e;
    --color-gray-100: rgba(255, 255, 255, 0.8);
    --primary-500: var(--brand-primary-500);
    --primary-400: var(--brand-primary-400);
    --primary-300: var(--brand-primary-300);
    --primary-200: var(--brand-primary-200);
    --primary-100: var(--brand-primary-100);
    --primary-50: var(--brand-primary-50);
    --color-cornflowerblue: #4799e0;
    --black: #000;
    /* Spacing */

    --xs: 0.5rem;
    --base: 1rem;
    --xl: 4rem;
    --lg: 2rem;
    --xl1: 6.5rem;
    --md: 1.5rem;
    --sm: 0.75rem;
    --xl2: 2.5rem;
    --xl3: 5.5rem;
    --xs1: 0.25rem;
    --2xl: 4rem;
    --3xl: 4.5rem;
    --4xl: 5.5rem;
    --5xl: 6.5rem;
    /* Gaps */
    --gap-5xl: 1.5rem;
    --gap-xl: 1.25rem;
    --gap-17xl: 2.25rem;
    --gap-base: 1rem;
    --gap-5xs: 0.5rem;
    --gap-45xl: 4rem;
    --gap-12xl: 1.9375rem;
    --gap-13xl: 2rem;
    --gap-21xl: 2.5rem;
    --gap-12xs: 0.062rem;
    --gap-20xl-5: 2.468rem;
    --gap-57xl-8: 4.8rem;
    --gap-0: 0rem;
    --gap-xs: 0.75rem;
    --gap-2xs: 0.25rem;
    /* Paddings */
    --padding-lgi: 1.187rem;
    --padding-45xl: 4rem;
    --padding-xl: 1.25rem;
    --padding-13xl: 2rem;
    --padding-11xs: 0.125rem;
    --padding-6xs: 0.437rem;
    --padding-12xl: 1.937rem;
    --padding-85xl: 6.5rem;
    --padding-33xl: 3.25rem;
    --padding-4xl: 1.437rem;
    --padding-2xs: 0.687rem;
    --padding-21xl: 2.5rem;
    --padding-7xs: 0.375rem;
    --padding-mini: 0.937rem;
    --padding-11xs-5: 0.093rem;
    --padding-5xl: 1.5rem;
    --padding-smi: 0.812rem;
    --padding-38xl: 3.562rem;
    --padding-19xl: 2.375rem;
    --padding-base: 1rem;
    --padding-69xl: 5.5rem;
    --padding-4xs: 0.562rem;
    --padding-22xl: 2.562rem;
    --padding-37xl: 3.5rem;
    --padding-212xl: 14.437rem;
    --padding-131xl: 9.375rem;
    --padding-49xl: 4.25rem;
    --padding-sm-8: 0.862rem;
    --padding-xs: 0.75rem;
    --padding-sm-7: 0.856rem;
    --padding-40xl: 3.687rem;
    --padding-68xl-2: 5.45rem;
    --padding-39xl: 3.625rem;
    --padding-10xl: 1.812rem;
    --padding-150xl-9: 10.618rem;
    --padding-151xl: 10.625rem;
    --padding-14xl: 2.062rem;
    --padding-89xl: 6.75rem;
    --padding-26xl: 2.812rem;
    --padding-51xl: 4.375rem;
    --padding-8xl: 1.687rem;
    --padding-23xl: 2.625rem;
    --padding-7xl: 1.625rem;
    --padding-16xl: 2.187rem;
    --padding-2xl: 1.312rem;
    --padding-3xl: 1.375rem;
    --padding-259xl: 17.375rem;
    --padding-120xl: 8.687rem;
    --padding-43xl: 3.875rem;
    --padding-111xl-5: 8.156rem;
    --padding-66xl: 5.312rem;
    --padding-20xl: 2.437rem;
    --padding-12xs: 0.062rem;
    --padding-9xs-5: 0.218rem;
    --padding-5xs: 0.5rem;
    --padding-9xs: 0.25rem;
    /* Border radiuses */
    --br-5xl: 24px;
    --br-5xs: 8px;
    --br-base: 16px;
    /* Effects */
    --shadow-component: 0px 24px 60px rgba(0, 0, 0, 0.2);
    --shadow-component-light: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
    --shadow-component-dark: 0px 24px 60px rgba(0, 0, 0, 0.6);
    --image-overlay: rgba(0, 0, 0, 0.75);
    --image-overlay-dark: rgba(0, 0, 0, 0.90);
    --image-overlay-light: rgba(0, 0, 0, 0.45);
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.line-clamp-4 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical; 
    overflow: hidden; 
    -webkit-line-clamp: 4;
    line-clamp: 4;
}

.line-clamp-6 {
    display: -webkit-box !important;
    -webkit-box-orient: vertical; 
    overflow: hidden; 
    -webkit-line-clamp: 6;
    line-clamp: 6;
}
.smallCardWrapper {
    gap: 2px!important;
    width: 1440px;
}

.swiper-pagination-bullets {
    gap: var(--base)
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    width: auto!important;
    min-width: 4.375rem;
}

.swiper-wrapper:not(.metal-selector-swiper .swiper-wrapper, .case-study-component .swiper-wrapper, .spotlight-swiper .swiper-wrapper, .case-study-grid-swiper .swiper-wrapper, .vertical-carousel .swiper-wrapper, .timeline .swiper-wrapper, .tab-content-swiper .swiper-wrapper, .testimonial .swiper-wrapper) {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.swiper-grid {
    width: 100%;
}

.swiper-grid .swiper-wrapper:not(.metal-selector-swiper .swiper-wrapper) {
    gap: 1px;
}

.timeline .swiper-wrapper {
    align-items: start;
    gap: 0rem;
    padding-left: .75rem;
}
.timeline .swiper {
    padding-right: 1.5rem;
}

.resourceCarousel .swiper-wrapper, .case-study-component .swiper-wrapper{
    gap: 0rem;
}

.resourceCarousel .swiper-horizontal {
    height: auto;
}

.overview-component .swiper-wrapper{
    display: flex;
    gap: 0rem;
}

.testimonial .swiper-wrapper {
    display: flex; /* Ensure wrapper uses flexbox */
    align-items: center; /* Center items vertically */
    gap: 0rem;
}

.swiper-horizontal {
    height: 100%;
}

.stickyNavMargin {
    padding-bottom: 95px;
}

.swiper-pagination-bullet {
    width: 0.75rem;
    height: 0.75rem;
    background-color: var(--charcoal-500);
    border: 1px solid var(--charcoal-500);
    opacity: 1;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--white)
}

.overview .swiper-pagination-bullet{
    border-color: var(--white);
    background-color: linear-gradient(223.59deg, var(--charcoal-500) 4.12%, #000000 95.88%);;
}

.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0;
}

.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    transform: translateX(0) !important;
}

.tb_nc_post_wrapper {
    width: 33% !important;
}

.pagination-product .MuiTablePagination-toolbar{
    align-items: baseline;
    padding: 0;
}

.pagination {
    transform: translateX(0) !important;
}

/* style for wistia video */

.w-chrome {
    height: 100% !important;
}

/* style for wistia video */


@media screen and (max-width: 1200px) {
    .tb_nc_post_wrapper {
        width: 50% !important;
    }
} 

@media screen and (max-width: 800px) {
    .timeline .swiper-wrapper {
        padding-left: 5rem;
    }

    .timeline .swiper {
        padding-right: 10rem;
    }

    .tb_nc_post_wrapper {
        width: 50% !important;
    }
} 

@media screen and (max-width: 450px) {
    .stickyNavMargin {
        padding-bottom: 68px;
    }

    .tb_nc_post_wrapper {
        width: 100% !important;
    }
}


@media screen and (max-width: 640px) {
    .vertical-carousel .swiper-slide:not(.cardTeamSwiper.vertical-carousel .swiper-slide) {
        width: 160px !important;
    }
} 
