/* General Styles */
.title,
.titleWrap,
.bodyWrap,
.aboutBanner,
.bodyWrap,
.content {
    margin: 0;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 4rem;
}

.title_alt {
    color: var(--primary-500, #00A19B);
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 4rem;
}

.sectionTitle {
    color: var(--charcoal-500, #37353C);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem; /* 155.556% */
}

:lang(de-de) {
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
}


.sectionCopy {
    color: var(--charcoal-500, #37353C);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

/* Specific Element Styles */
.title_alt {
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    max-width: 100%;
}

.titleWrap {
    width: 32rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-right: var(--padding-3xl);
}

.leveragingInnovativeTechnolo {
    width: 30.75rem;
    font-size: var(--button-size);
    line-height: 1.5rem;
    display: inline-block;
}

.bodyWrap {
    width: 32rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: var(--padding-xl) var(--padding-xl) var(--padding-xl) 0;
    min-height: 10.25rem;
    font-size: 18px;
}

.aboutBanner {
    width: 100%;
    background-color: var(--color-gray-100);
    justify-content: center;
    padding: var(--xl) 0;
    line-height: normal;
    letter-spacing: normal;
    flex: 1;
    text-align: left;
    font-size: var(--h4-size);
    color: var(--white);
    font-family: var(--standard-font);
    max-width: 1440px;
    margin: 0 auto;
    align-self: stretch;
    flex-direction: row;
    gap: var(--xl);
    position: relative;
    overflow: hidden;
    padding-left: var(--5xl);
    padding-right: var(--5xl);
    margin-bottom: var(--xl2);
}

.aboutBanner.dark {
    background-color: var(--charcoal-500);
}

.aboutBanner.dark .title,
.aboutBanner.dark .content,
.aboutBanner.dark .sectionTitle,
.aboutBanner.dark .sectionCopy,
.aboutBanner.dark .sectionTitle {
    color: var(--white);
}

.backgroundMedia {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

/* Flexbox Containers */
.content {
    width: 77rem;
    display: flex;
    justify-content: space-between;
    padding: 0 var(--5xl);
    row-gap: 20px;
    gap: 0;
    text-align: left;
    font-size: var(--h1-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}

.aboutBanner,
.bodyWrap,
.content {
    display: flex;
    align-items: center;
}

.noTopMargin {
    margin-top: 0;
}

/* Media Queries */
@media screen and (max-width: 1350px) {
    .content {
        /*flex-wrap: wrap;*/
        padding-left: 48px;
        padding-right: 48px;
    }
}


@media screen and (max-width: 1200px) {
    .title {
        font-size: 2.4375rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3rem;
        color: var(--charcoal-500, #37353C);
    }

    .title_alt {
        font-size: 2.4375rem;
        color: var(--primary-500, #00A19B);
        font-style: normal;
        font-weight: 600;
        line-height: 3rem;
    }

    .sectionTitle {
        color: var(--charcoal-500, #37353C);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.75rem; /* 155.556% */
    }

    .sectionCopy {
        color: var(--charcoal-500, #37353C);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem; /* 150% */
    }

    .content {
        padding-left: var(--xl1);
        padding-right: var(--xl1);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        flex: none;
        order: 1;
        flex-grow: 0;
        gap: var(--base);
    }

    .titleWrap {
        justify-content: center;
        padding-right: 0;
        line-height: 0;
    }


    .aboutBanner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: var(--md);
        box-sizing: border-box;
        max-width: 100%;
        width: auto;
    }

    .bodyWrap {
        min-height: 0;
        padding: 0;
    }
}

@media screen and (max-width: 640px) {
    .titleWrap {
        justify-content: flex-start;
        line-height: 0;
    }
}