.imageIcon {
    align-self: stretch;
    height: 180px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.linkWrapper {
    text-decoration: none;
}

.title {
    position: relative;
    letter-spacing: 0.1em;
    line-height: 20px;
    text-transform: uppercase;
    display: inline-block;
    min-width: 72px;
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 125% */
    letter-spacing: 1.6px;
    text-transform: uppercase;
}

.playButtonWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--image-overlay-light); /* Light grey overlay */
    transition: background-color 0.3s ease-in-out; /* Optional transition for overlay */
    z-index: 1; /* Places the overlay above the video but below the content */
}

.imageContainer {
    position: relative;
    width: 100%; /* or specific width */
    height: auto; /* Maintain aspect ratio */
}

.description {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-family: var(--standard-font);
    color: var(--white, #FFF);
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem; /* 96% */
}

.copy {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--md);
}

.link {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 93px;
}

.blogCard,
.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.blogCard.resourceCarousel:hover {
    background-color: var(--primary-500);
}

.blogCard.resourceCarousel {
    transition: background-color 0.3s ease-in-out; /* Adjust duration and easing as needed */
}

    .blogCard.resourceList:hover {
        box-shadow: 0px 24px 60px 0px rgba(0, 0, 0, 0.20);
    }

    .blogCard.resourceList {
        transition: box-shadow 0.3s ease-in-out; /* Adjust duration and easing as needed */
    }

.blogCard.blogGrid:hover {
    box-shadow: 0px 24px 60px 0px rgba(0, 0, 0, 0.20);
}

.blogCard.blogGrid {
    transition: box-shadow 0.3s ease-in-out; /* Adjust the duration and easing function as needed */
}

.content {
    align-self: stretch;
    flex: 1;
    justify-content: space-between;
    gap: var(--md);
}

.blogCard {
    background-color: var(--charcoal-500);
    justify-content: flex-start;
    padding: var(--base);
    box-sizing: border-box;
    gap: var(--md);
    max-width: 100%;
    text-align: left;
    font-size: var(--button-size);
    color: var(--white);
    font-family: var(--standard-font);
    display: flex;
    width: 328px;
    height: 450px;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--md, 24px);
}

@media screen and (max-width: 450px) {
    .precisionInProduction {
        font-size: var(--font-size-xl);
        line-height: 19px;
    }
}

.blogCard.resourceList {
    background-color: var(--white);
}

.blogCard.resourceList .content .title,
.blogCard.resourceList .link {
    color: var(--primary-500, #2273BA);
}


.blogCard.resourceList .content .description {
    color: var(--charcoal-500);
}

.resourceList .imageIcon {
    align-self: stretch;
    /*height: 175px;*/
    width: 296px;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
}

.resourceList .title {
    position: relative;
    letter-spacing: 0.1em;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--primary-500, #2273BA);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem; /* 125% */
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}


.link {
    color: var(--white, #FFF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
    text-decoration: none;
    transition: width 0.3s ease-in, padding 0.3s ease-in;
}

    .link:hover {
        text-decoration: underline;
        transition: width 0.3s ease-in, padding 0.3s ease-in;
        color: var(--white);
        text-underline-offset: 4px;
    }

.link:hover::after {
    width: 100%; /* Expand underline to full width */
    padding-top: 4px; /* Adds padding above the line */
}

.resourceList .link {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 125% */
    text-decoration: none;
    transition: width 0.3s ease-in, padding 0.3s ease-in;
}

    .resourceList .link:hover {
        text-decoration: underline;
        transition: width 0.3s ease-in, padding 0.3s ease-in;
        text-underline-offset: 4px;
    }

    .resourceList .link:hover::after {
        width: 100%; /* Expand underline to full width */
        padding-top: 4px; /* Adds padding above the line */
    }


.resourceList .description {
    margin: 0;
    align-self: stretch;
    position: relative;
    color: var(--charcoal-500, #37353C);
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.7rem; /* 96% */
}

.resourceList .copy {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--md);
}

.resourceList .link {
    position: relative;
    line-height: 20px;
    font-weight: 500;
}

.resourceList .blogCard,
.resourceList .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.resourceList .content {
    align-self: stretch;
    flex: 1;
    justify-content: space-between;
}

.resourceList .copy .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 18.75rem;
    color: var(--white);
}
.resourceList .copy .description {
    padding: var(--md) 0 .125rem 0 ;
    color: var(--white);
}

.blogCard.resourceList {
    height: 450px;
    border: 1px solid var(--charcoal-200);
    box-sizing: border-box;
    justify-content: flex-start;
    padding: var(--base) var(--padding-mini);
    gap: var(--md);
    max-width: 100%;
    text-align: left;
}

.blogGrid {
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border: 1px solid var(--charcoal-200);
}

.blogGrid .copy .title {
    color: var(--primary-500);
}

.blogGrid .copy .description {
    color: var(--charcoal-500);
}

.blogGrid .content .link {
    color: var(--primary-500);
}

@media screen and (max-width: 450px) {
    .component_type .precisionInProduction {
        font-size: var(--font-size-xl);
        line-height: 19px;
    }
}

