.innerWrapper {
    padding: 0 2.5rem;
}

h1 {
    margin: 0!important;
}

.border {
    background: var(--charcoal-300, #7E7E7E);
    height: 1px;
    margin-top: 2rem;
}

.currentBreadcrumb,
.currentBreadcrumb:hover {
    color: var(--charcoal-500);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.125rem */
    text-decoration: none;
}

.breadcrumbLink,
.breadcrumbLink:hover {
    color: var(--charcoal-300, #7E7E7E);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--xs1);
    position: relative;
    line-height: 150%;
    font-weight: 500;
}

.containerHeroTitle{
    margin-top: 15px;
}

.title {
    font-size: 61px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    max-width: 46.5625rem;
    color: var(--Charcoal-Global, #37353C);
}

.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    color: var(--black, #000);
    margin-top: 10px;
}

    .description a {
        color: var(--black, #000);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        text-underline-offset: 2px;
    }



@media screen and (max-width: 450px) {
    .title {
        font-size: 45px;
        text-align: center;
    }
    .description{
        text-align: center;
    }
    .border{
        margin-top: 0;
    }
}