/* Navbar and Links Styles */
.navbar {
    width: 100%;
    background-color: var(--white);
    border-bottom: 0.3px solid var(--charcoal-500);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--base) 0 0 var(--lg);
    top: 0;
    z-index: 99;
    position: sticky;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: var(--button-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}

.mainLink {
    color: var(--charcoal-500, #37353C);
    text-align: center;
    /* Button */
    font-family: var(--standard-font);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    text-decoration: none;
}

.mainLink.active::after {
    position: absolute;
    bottom: 10px;
    height: 4px;
    width: 100%;
    background-color: var(--primary-500);
    content: "";
    left: 0;
    bottom: -20px;
    z-index: 999;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 1;
    justify-content: space-between;
    max-width: 100%;
    gap: var(--gap-xl);
    position: relative;
    margin-bottom: 18px;
}

.navbarLink {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 0 var(--xs1) 0;
    text-decoration: none;
    color: black;
    position: relative;
}

.navLink {
    text-decoration: none;
    width: 180px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    color: inherit;
    align-items: center;
    white-space: nowrap;
    display: flex;
    justify-content: center;
}

.dropdown {
    display: flex;
    animation: slideDown 0.3s ease-in-out;
    position: fixed;
    top: 81px;
    left: 0;
    width: 100%;
    background-color: transparent;
    z-index: 99;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    text-align: center;
    box-sizing: border-box;
    z-index:1000;
}

.megaMenu {
    width: 100%;
    background-color: var(--white);
    justify-content: flex-start;
    padding: 3rem var(--xl1);
    box-sizing: border-box;
    gap: var(--xs);
    line-height: normal;
    letter-spacing: normal;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.25);
}

.innerMenuWrap {
    max-width: 1440px;
    margin: 0 auto; /* Center the mega menu */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 6rem;
}

.dropdownLink {
    display: block;
    padding: 12px 16px;
    text-decoration: none;
    color: var(--charcoal-500);
}

.dropdownLink:hover {
    background-color: var(--primary-500);
    color: var(--white);
}

.linkGroup {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
    max-width: 100%;
}

.bannerImage {
    width: 100%;
}

.dropdownTitle {
    color: var(--primary-500, #2273BA);
    font-family: var(--standard-font);
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    text-decoration: none;
    padding: 16px 8px;
}

.dropdownTitle:hover {
    text-decoration: underline;
}

.Menul2description,
.multiSlide {
    align-self: stretch;
    position: relative;
    line-height: 20px;
}



.multiSlide {
    font-weight: 500;
    color: var(--charcoal-500, #37353C);
    font-family: var(--standard-font);
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    text-decoration: none;
}

.Menul2description {
    font-size: 13px;
    padding-top: var(--xs, 0.5rem);
    font-weight: 400;
}

.megaMenuL2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--xs);
    text-align: left;
    font-size: var(--button-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
    padding: 16px 8px;
}

.megaMenuL2:hover {
    color: var(--primary-500, #2273BA);
    cursor: pointer;
}

.linkGroup,
.subLinks {
    max-width: 100%;
    display: flex;
    align-items: flex-start;
}

.subLinks {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--base) 140px; 
}

.linkGroup {
    width: 1232px;
    flex-direction: row;
    justify-content: space-between;
    line-height: normal;
    letter-spacing: normal;
    gap: 20px;
}

.mobileNav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 1000;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-100%);
}

.menuOpen {
    transform: translateX(0);
}


.mobileMenuContent {
    padding: 20px;
}

.navMobileContainer, .navMobileContainerL2 {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 88px;
    right: -100%;
    background-color: #ffffff;
    transition: right 0.3s ease;
    overflow-x: hidden;
    z-index: 1000;
}


.navMobileContainer.open, .navMobileContainerL2.open {
    right: 0;
}

.menuMobileLink{
    color: var(--charcoal-500);
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-decoration: none;
}

.menuItemWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.menuItemL2Wrapper {
    gap: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdownBannerCol {
    grid-column: 3;
}

.navbarLinkL2 a:hover, .navbarLink a:hover{
    color: var(--primary-500);
}

.logoImageDropdown{
    max-height: 55px;
    width: auto;
}

.mainLink:hover {
    color: var(--primary-500)!important;
}

.mobileCTA {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    background-color: var(--background-color, white); /* Optional background color */
    display: flex;
    flex-direction: column;
    gap: 2.25rem; /* Space between buttons */
    z-index: 9999;
    height: 180px;
    overflow: hidden;
}

.mobileCTAButtons {
    display: flex;
    flex-direction: row;
    gap: var(--sm);
    padding: 0 8px;
    justify-content: space-between;
}

.mobileBottomWrapper {
    display: flex;
    flex-direction: row;
    background: var(--primary-50, #F4F8FC);
    height: 3rem;
    justify-content: space-between; /* Aligns children to the left and right */
    align-items: center; /* Aligns items vertically in the center */
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}


@media screen and (max-width: 1200px) {
    .links {
        display: none;
    }  
  
    .navbarLink:hover{
        background-color: var(--brand-primary-50) !important;
   
    }
    .navbarLink{
        display: block;
        padding: 25px;
        border-bottom: 0.25px solid rgb(191, 191, 191);
    }

    .navbarLinkTitleL2 {
        border-bottom: 1px solid var(--brand-primary-500);
    }

        .navbarLinkTitleL2 a {
            color: var(--primary-500);
            font-family: var(--standard-font);
            font-size: 24px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
        }

    .navbarLinkL2 a {
        color: var(--charcoal-500, #37353C);
        font-family: var(--standard-font);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .logoImageDropdown{
        max-height: 20px;
        width: auto;
        padding-left: 10px;
    }
    .linkGroup {
        flex-wrap: wrap;
    }

    .megaMenu {
        padding-left: var(--xl1);
        padding-right: var(--xl1);
    }

    .mobileLocationsLink a {
        color: var(--charcoal-500);
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 125% */
        text-decoration: none;
        padding-left: 1.5rem;
    }

    .mobileLocationsLink a:hover {
        color: var(--primary-500);
    }
    .navbarLinkL2{
        padding: 0;
    }
    .navbarLinkL2Item{
        padding: 1.5625rem;
        height: 100%;
        width: 100%;

    }
    .mobileCTAButtons a,
    .mobileCTAButtons button {
        width: 50% !important;
    }
}

@media screen and (max-width: 450px) {
    .megaMenu {
        padding-left: var(--xl1);
        padding-right: var(--xl1);
    }

    .mobileLocationsLink a {
        color: var(--charcoal-500);
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem;
        text-decoration: none;
    }

    .navMobileContainer, .navMobileContainerL2 {
        top: 72px;
    }
}
