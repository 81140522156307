.languageLink {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--xs1);
    position: relative; /* Ensure the parent container is positioned relatively */
}

.languageDropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.worldIcon, .chevronDownIcon {
    margin-right: 8px;
}

.languageSelector {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -18px;
    right: -22px;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: translateY(-20%);
    opacity: 0;
    visibility: hidden;
    width: 305px;
    padding: var(--md) var(--md) var(--base);
    box-sizing: border-box;
    gap: var(--md);
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--button-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
    z-index: 99;
}

.selectedLocale {
    color: var(--charcoal-500);
    min-width: 40px;
}

.selectedLocale:hover {
    color: var(--primary-500);
}

.languageSelector.visible {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectYourLanguage {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
}

.closeIcon {
    height: 18px;
    width: 18px;
    position: relative;
    cursor: pointer;
}

.navItem {
    cursor: pointer;
    border-bottom: 0.25px solid var(--charcoal-500, #37353C);
    display: flex;
    padding: var(--base, 1rem);
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.navItem:last-child {
    border-bottom: none;
}

.navLink {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 103px;
}

.navLink:hover {
    color: var(--primary-500);
}

.changeText {
    color: var(--primary-500, #2273BA);
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    padding-right: 1.5rem;
    padding-left: .5rem;
}

@media screen and (max-width: 1200px) {
    .selectYourLanguage {
        color: var(--primary-500);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem; /* 133.333% */
    }

    .selectedLocale {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 125% */
    }

    .languageSelector.visible {
        position: fixed;
        top: 88px;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: white;
        overflow-y: auto;
        z-index: 9999;
        padding: initial;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
    }

    .languageSelector {
        transform: translateY(100%); /* Off-screen initially */
    }

    .languageLink {
        padding-right: 1.5rem;
    }

    .changeText {
        padding-right: inherit;
    }

    .navItemGroup {
        padding: inherit;
        height: 72vh;
        overflow-y: scroll;
    }

    .navItem {
        padding: 1.25rem 2.25rem;
        margin-bottom: initial;
    }
}


@media screen and (max-width: 450px) {
    .languageSelector.visible {
        top: 72px;
    }
}
