.tab {
    text-decoration: none;
    width: 100%;
    color: var(--charcoal-500);
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-2xs) var(--padding-40xl) var(--padding-4xs);
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 96.774% */
    box-sizing: border-box;
    min-height: 44px;
    height: 100%;
    cursor: pointer;
}

.swiperSlide {
    width: auto;
    flex: 1 auto;
    min-height: 44px;
    height: auto !important;
    min-width: 472px;
    border-bottom: 1px solid var(--charcoal-300, #7E7E7E);
}

.swiper {
    width: 100%;
    padding-left: 0;
    overflow: visible;
}

.horizontalTab,
.horizontalTabWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.horizontalTabWrapper {
    border-bottom: 1px solid var(--charcoal-300, #7E7E7E);
}

/* Add this to your TabBar.module.css */
.hiddenContent {
    display: none;
}

.activeContent {
    display: flex;
}

.tabContainer {
    max-width: 1440px;
    margin: var(--xl) auto;
}

.tabContent {
    padding: var(--2xl) var(--2xl) 0 var(--5xl);
    align-items: center;
    align-self: stretch;
}

.tabContentLeft {
    display: flex;
    flex-direction: column;
    width: 44rem;
}


.title {
    color: var(--charcoal-500, #37353C);
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 123.077% */
}

.titleAlt {
    color: var(--primary-500, #2273BA);
    font-size: 39px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 123.077% */
}

.summary {
    color: var(--charcoal-500, #37353C);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin: 1rem 0;
}

.content {
    color: var(--charcoal-500, #37353C);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 2rem 3rem;
    flex-direction: column;
}

.content ul {
    column-count: 2; /* Specifies the number of columns */
    column-gap: 60px; /* Spacing between the columns */
    list-style: disc inside; /* Optional, adjust the list style */
    margin: 0;
    padding: 0 0 0 1rem;
}

.content ul li {
    list-style-position: outside;
}

.horizontalTab {
    cursor: pointer;
    padding: var(--sm) var(--padding-xl) 8px;
    background-color: var(--white);
    box-shadow: var(--shadow-cta);
    border-radius: var(--xs) var(--xs) 0 0;
    flex: 0.9024;
    max-width: 33%;
    flex-direction: row;
    height: 44px;
    color: var(--charcoal-500, #37353C);
    text-align: center;
    padding-bottom: 1rem;
}

    .horizontalTab:hover,
    .activeTab {
        border-radius: var(--xs, 0.5rem) var(--xs, 0.5rem) 0rem 0rem;
        border-bottom: 3px solid var(--primary-500, #2273BA);
        background: var(--white, #FFF);
        /* shadow-cta */
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
        z-index: 99;
    }

.horizontalTabWrapper {
    flex: 1;
    flex-direction: row;
    padding: 0;
}

.tabsFlexible {
    width: 1440px;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    margin: 0 auto;
    font-size: var(--h4-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}

.tabContainer {
    display: flex;
    flex-direction: column;
}

.resourceTitle {
    color: var(--charcoal-500, #37353C);
    font-size: var(--font-size-12xl);
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 96.774% */
    padding-left: 8rem;
}

@media screen and (max-width: 1439px) {
    .tabContainer {
        margin: var(--xl) var(--xl2);
    }
    .swiperSlide {
        min-width: 0;
    }
    .tab {
        padding: var(--base);
        flex-wrap: wrap;
        font-size: var(--sub-cta-content);
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .tabContainer {
        margin: var(--xl2);
    }
}

@media screen and (max-width: 992px) {
    .tabsFlexible {
        flex-wrap: wrap;
    }

    .automotive,
    .automotive1,
    .automotive2,
    .automotive3 {
        font-size: var(--font-size-6xl);
        line-height: 24px;
    }

    .tabContent {
        width: 100%;
        flex-direction: column;
        padding: var(--md) 0 0;
    }

    .tabContent .tabContentLeft,
    .tabContent .content {
        width: 100%;
        justify-content: flex-start;
    }

    .title,
    .titleAlt {
        font-size: var(--font-size-12xl);
        line-height: 1.875rem;
    }
}

@media screen and (max-width: 640px) {
    .tabContainer {
        margin: var(--xl2) var(--base);
    }

    .automotive,
    .automotive1,
    .automotive2,
    .automotive3 {
        font-size: var(--font-size-lgi);
        line-height: 18px;
    }

    .horizontalTab {
        flex: 1;
        padding-left: var(--padding-xl);
        padding-right: var(--padding-xl);
        box-sizing: border-box;
    }

    .swiperSlide {
        min-width: 260px;
    }

    .content ul{
        display: flex;
        flex-direction: column;
    }

    .content{
        padding: 1rem 1rem;
    }
}

