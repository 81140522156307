/* General Styles */
.logo {
    text-decoration: none;
    position: relative;
    line-height: 24px;
    display: inline-block;
    max-height: 95px;
    color: var(--primary-500, #2273BA);
    font-family: var(--standard-font);
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    align-items: center;
}

.container {
    flex: 1;
    padding: var(--xl2) var(--3xl);
    gap: 180px;
    text-align: left;
    font-size: var(--h4-size);
    font-family: var(--standard-font);
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-self: stretch;
    letter-spacing: normal;
    color: var(--primary-500);
    overflow: hidden;
    border-top: 1px solid var(--charcoal-200);
    border-bottom: 1px solid var(--charcoal-200);
}

.logoImage {
    max-width: 125px;
    max-height: 125px;
}

.slide {
    display: flex; /* Enable flexbox for slide */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    width: auto; /* Make width auto to fit content */
}

/* Media Queries */
@media screen and (max-width: 975px) {
    .container {
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 950px) {
    .container {
        padding: 10px 24px;
    }
}

@media screen and (max-width: 450px) {
    .logo {
        font-size: var(--font-size-xl);
        line-height: 19px;
    }

    .container {
        padding: 10px 24px;
    }

    .logoImage {
        max-width: 43px;
        max-height: 43px;
    }
}
