.filterButtonWrapper {
    display: flex;
    gap: var(--md);
    align-items: center;
    padding: var(--padding-xl) var(--md);
    border-bottom: 1px solid var(--brand-primary-500);
}

.filterButtonWrapper > p {
    color: var(--brand-primary-500);
    font-size: var(--sub-cta-content);
    margin: 0;
}

.closeButtonWrapper {
    justify-content: right;
    display: flex;
    align-items: end;
    margin: var(--gap-5xl) var(--gap-xl);
}

.closeButton {
    width: 24px;
    height: 24px;
}

.content {
    display: block;
}

.filterContainer {
    display: none;
}

@media screen and (max-width: 1024px) {
    .filterButtonWrapper {
        padding: var(--base) var(--md);
    }
    .filterContainer {
        display: block;
    }
    .boxWrapper {
        display: none;
    }
}
