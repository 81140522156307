.tabWrapper{
    display: flex;
    padding: 0;
    max-width: 1440px;
    margin: 3rem auto;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.tabItem {
    border-bottom: .0625rem solid var(--charcoal-300);
    width: 100%;
    text-align: center;
    padding: var(--sm) var(--base);
    font-size: var(--font-size-12xl);
    line-height: 1.875rem;
    font-weight: 500;
}

.tabItemSelected {
    border-bottom: .1875rem solid var(--brand-primary-500);
    border-radius: var(--xs) var(--xs) 0rem 0rem;
    box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, .25);
}

.imageContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1px 0;
    box-sizing: border-box;
    width: 50%;
    background: var(--black);
}

.imageIcon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    color: var(--white);
    font-size: 2.4375rem;
    font-weight: 600;
    line-height: 3rem;
    margin: 0;
}

.description {
    color: var(--white);
    position: relative;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    margin: 0;
}


.button {
    background-color: var(--primary-500);
    color: var(--white);
    padding: 11px 39px;
    border: 1px solid var(--primary-500);
    border-radius: var(--md);
    min-width: 250px;
    width: 250px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 300ms ease-out, box-shadow 300ms ease-out;
    display: inline-block;
    box-sizing: border-box;
}


.contentWrapper {
    position: relative;
    z-index: 1; 
    background-image: var(--gradient-charcoal, linear-gradient(224deg, #37353C 4.12%, #000 95.88%));
    width: 50%;
    padding: var(--4xl, 5.5rem) var(--2xl, 4rem);
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    text-align: start;
}

.imageLogo {
    max-height: 3.5rem;
    max-width: 12.2rem;
}

.container {
    display: flex;
    padding: 0;
    max-width: 1440px;
    margin: 3rem auto;
    width: 100%;
    box-sizing: border-box;
}

.reverse{
    flex-direction: row-reverse;
}


@media screen and (max-width: 768px) {
    .container{
        flex-direction: column;
    }
    .title {
        flex-wrap: wrap;
        font-size: var(--font-size-12xl);
        font-weight: 500;
    }

    .imageContainer{
        width: 100%;
    }
    .contentWrapper{
        width: 100%;
        padding: var(--md);
    }
}
