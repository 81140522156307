.containerHeroTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    align-items: center;
    padding: 4rem 3rem;
    gap: var(--base);
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Proxima Nova';
    background-color: var(--white);
    min-height: 272px;
    color: var(--charcoal-500);
    max-width: 1440px;
    margin: 0 auto;
}

.currentBreadcrumb,
.currentBreadcrumb:hover {
    color: var(--charcoal-500);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.125rem */
    text-decoration: none;
}

.breadcrumbLink,
.breadcrumbLink:hover {
    color: var(--charcoal-300, #7E7E7E);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--xs1);
    position: relative;
    line-height: 150%;
    font-weight: 500;
}

.containerHeroTitle.textAlignLeft {
    align-items: flex-start;
}

.containerHeroTitle.textAlignCenter {
    align-items: center;
}

.containerHeroTitle .content {
    text-align: center;
}

.containerHeroTitle.bottomBorder {
    border-bottom: 0.5px solid #37353C;
}

    .eyebrow {
        text-transform: uppercase;
        color: var(--primary-500);
        display: flex;
        flex-direction: column;
        padding: 0px;
        align-items: flex-start;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.1em;
    }



.title {
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--charcoal-500);
}

h1.title {
    font-size: 61px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

h3.title {
    font-size: 49px;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem;
}


@media screen and (max-width: 1200px)  {
    .h1.title,
    .h3.title {
        padding: 24px 16px;
        gap: var(--base)
    }


    .h1.title {
        font-size: 43px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 96.774% */
    }

    .h3.title {
        font-size: var(--font-size-12xl);
        font-style: normal;
        font-weight: 500;
        line-height: 30px; /* 96.774% */
    }
}
