.container{
    max-width: 1440px;
    margin: 0 auto;
    padding: var(--padding-6xs) var(--padding-xl);
}
.resourcesAside{
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: var(--md);
    max-width: 275px;
}

.resourcesAsideTitle{
    font-weight: 700;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: var(--charcoal-500);
}

.resourcesAsideSnippetTitleContainer svg {
    height: 45px;
    width: 45px;
}

.resourcesAsideSnippet {
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px 0px 24px;
    gap: 4px;
    background: var(--white);
    display: flex;
    border-bottom: 0.5px solid #BFBEBE;
}

.resourcesAsideSnippetTitleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 15px;
    min-width: 275px;
    color: var(--charcoal-500)
}

.resourcesAsideSnippetThumbnail{
    order: 0;
    flex-grow: 0;
    height: 2.25rem;
    width: 2.2rem;
}

.resourcesAsideSnippetTitle {
    color: var(--charcoal-500, #37353C);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 153.846% */
}


.snippetContentContainer
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    order: 1;
}

.snippetSubTitle,
.snippetCTA {
    font-weight: 400;
    display: flex;
    align-items: center;
    max-width: 215px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--charcoal-300, #7E7E7E);
    font-size: 0.8125rem;
    line-height: 1.25rem; /* 153.846% */
}

.snippetCTA {
    color: var(--primary-500);
    text-decoration: none;
    color: var(--primary-500, #2273BA);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 153.846% */
}

.snippetCTA:hover {
  text-decoration: underline;
}
@media screen and (max-width: 1024px){
    .container{
        padding: var(--padding-6xs) var(--padding-xl);
    }
}

