.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    align-self: stretch;
    align-content: flex-start;
    justify-content: center;
    padding: 0 var(--padding-12xs);
    box-sizing: border-box;
    gap: var(--md);
    max-width: 100%;
}

.largeCardGrid {
    max-width: 100%;
    align-items: center;
    justify-content: center;
}

.largeCardGrid {
    gap: var(--md);
}

.largeCardGrid {
    width: 1440px;
    display: flex;
    flex-direction: column;
    padding: var(--md);
    box-sizing: border-box;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    font-size: var(--button-size);
    color: var(--white);
    font-family: var(--standard-font);
    margin: auto;
}

@media screen and (max-width: 750px) {
    .automotive,
    .automotive1 {
        font-size: var(--font-size-12xl);
        line-height: 38px;
    }
}

@media screen and (max-width: 675px) {
    .largeSquareCard,
    .largeSquareCard1 {
        min-width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .automotive,
    .automotive1 {
        font-size: var(--font-size-4xl);
        line-height: 29px;
    }
}
