.message {
    align-self: stretch;
    position: relative;
    letter-spacing: 0.02em;
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
}

.footerMessage {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-6xs) 0 0;
    box-sizing: border-box;
    min-width: 29.563rem;
    max-width: 100%;
}

.message1 {
    position: relative;
    line-height: 1.25rem;
    text-align: center;
    font-size: var( --small-size);
    font-weight: 400;
    line-height: 0.75rem;
}

.legalInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--padding-11xs) 0 0;
    box-sizing: border-box;
    max-width: 100%;
}

.brandmarkIcon,
.brandmarkIcon1,
.brandmarkIcon2 {
    height: 1.5rem;
    width: 1.513rem;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.brandmarkIcon img,
.brandmarkIcon1 img,
.brandmarkIcon2 img {
    height: 1.5rem;
    width: 1.5rem;
}

.container,
.footerBranding {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: var(--gap-5xl);
    max-width: 100%;
    font-size: var(--small-size);
}

.container {
    align-self: stretch;
    background-color: var(--charcoal-500);
    border-top: 0.5px solid var(--charcoal-300);
    box-sizing: border-box;
    overflow: hidden;
    flex-wrap: wrap;
    padding: var(--padding-lgi) var(--padding-45xl) var(--padding-xl);
    flex-shrink: 0;
    z-index: 1;
    text-align: left;
    font-size: var(--micro-size);
    color: var(--white);
    font-family: var(--standard-font);
}

.bottomFooter {
    align-self: stretch;
    box-sizing: border-box;
    overflow: hidden;
    flex-wrap: wrap;
    flex-shrink: 0;
    z-index: 1;
    text-align: left;
    font-size: var(--h4-size);
    color: var(--white);
    font-family: var(--standard-font);
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    flex: 1 1;
    row-gap: 20px;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
}

.bottomFooter-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
    gap: var(--gap-5xl);
}

@media screen and (max-width: 1200px) {

    .bottomFooter-right {
        flex-direction: column;
    }

    .bottomFooter {
        gap: var(--base);
        padding: 0;
        text-align: center;
        width: 50%;
        justify-content: center;
    }

    .legalInfo {
        min-width: none;
    }

    .footerMessage {
        min-width: 100%;
        order: 3;
        padding: 0;
        line-height: 12.18px;
        letter-spacing: 0.02em;
        font-size: var(--extra-small-size);
    }

    .footerBranding {
        flex-wrap: wrap;
        flex: none;
        order: 1;
        gap: var(--base);
    }

    .container {
        padding: var(--base) var(--xl);
        box-sizing: border-box;
    }

    .message1 {
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 153.846% */
    }
}

@media screen and (max-width: 450px) {
    .footerBranding {
        width: 100%;
    }
}
