.beyondTheBasics {
    align-self: stretch;
    position: relative;
    letter-spacing: 0.1em;
    line-height: 20px;
    text-transform: uppercase;
}

.dieCastingResources {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: var(--h3-size);
    line-height: 48px;
    font-weight: 600;
    font-family: var(--standard-font);
}

.cards,
.title {
    justify-content: flex-start;
}

.title {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--md);
    text-align: center;
    font-size: var(--button-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}

.cards {
    width: 1048px;
    display: grid;
    flex-direction: row;
    align-items: flex-start;
    gap: var(--lg);
    max-width: 100%;
    grid-template-columns: repeat(3, minmax(246px, 1fr));
}

.viewAll {
    flex: 1;
    position: relative;
    font-size: var(--button-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--standard-font);
    color: var(--white);
    text-align: center;
    display: inline-block;
    min-width: 128px;
}

.chevronRightSmall {
    height: 10px;
    width: 5px;
    position: relative;
    object-fit: contain;
}

.contentGroup {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--xs);
}

.blogGrid,
.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.button {
    cursor: pointer;
    border: 1px solid var(--primary-500);
    padding: 9px 53px;
    background-color: var(--primary-500);
    width: 250px;
    border-radius: var(--md);
    justify-content: center;
    min-width: 250px;
}

.blogGrid {
    width: 100%;
    justify-content: flex-start;
    padding: 0 38px;
    gap: var(--xl);
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 60px;
}

@media screen and (max-width: 850px) {
    .dieCastingResources {
        font-size: var(--font-size-12xl);
        line-height: 38px;
    }

    .cards {
        justify-content: center;
        grid-template-columns: repeat(2, minmax(246px, 426px));
    }

    .blogGrid {
        gap: var(--xl);
    }
}

@media screen and (max-width: 675px) {
    .cards {
        gap: var(--lg);
        grid-template-columns: minmax(246px, 1fr);
    }
}

@media screen and (max-width: 450px) {
    .dieCastingResources {
        font-size: 23px;
        line-height: 29px;
    }

    .blogGrid {
        gap: var(--xl);
    }
}
