.contactWrapper {
    max-width: 1440px;
    margin: auto;
    padding-top: 2rem;
}

.title {
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.description a {
    color: var(--black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-underline-offset: 2px;
}

.line {
    width: 100%;
    height: 1px;
    margin: 3rem 0;
}

.content {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.image {
    width: 35.793rem;
    flex-shrink: 0;
    align-self: stretch;
    padding-left: 4rem;
}

.innerWrapper {
    padding: 0 4rem;
}

.iframe {
    width: 100%;
    height: 60rem;
}

.iframeWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.formTitle {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem; /* 125% */
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: var(--black, #000);
}

.formDescription {
    color: var(--black, #000);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
    margin-bottom: .5rem;
}


.currentBreadcrumb,
.currentBreadcrumb:hover {
    color: var(--charcoal-500);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.125rem */
    text-decoration: none;
}

.breadcrumbLink,
.breadcrumbLink:hover {
    color: var(--charcoal-300, #7E7E7E);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--xs1);
    position: relative;
    line-height: 150%;
    font-weight: 500;
}

@media screen and (max-width: 1000px) {
    .imageWrapper {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .title {
        font-size: 45px;
    }

    .innerWrapper {
        padding: 0 2rem;
    }

    .line {
        margin: 1rem 0;
    }
}