.productFamily {
    margin: 0;
    color: var(--charcoal-500, #37353C);
    text-align: left;
    font-size: 49px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.divider,
.description,
.productFamily {
    align-self: stretch;
    position: relative;
}

.divider {
    height: 0.5px;
    border-top: 0.5px solid var(--charcoal-200);
    box-sizing: border-box;
}

.description {
    font-size: var(--button-size);
    line-height: 24px;
}

.buttonGroup,
.content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.buttonGroup {
    align-self: stretch;
    flex-direction: row;
    padding: 0 22px 0 0;
    gap: var(--md);
}

.content {
    flex: 1;
    flex-direction: column;
    box-sizing: border-box;
    gap: var(--lg);
    min-width: 333px;
    max-width: 100%;
}

.largeVideoIcon {
    align-self: stretch;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    height: 327px;
    width: 512px;
    object-fit: cover;
}

.mediaModal {
    max-width: 512px;
    max-height: 327px;
    height: auto;
    width: auto;
    object-fit: contain;
}

.wistiaVideoModal {
    min-width: 512px;
}

.videoWrapper {
    position: relative;
}

.largeVideoIcon.playing {
    opacity: 1;
}

.blankCardIcon,
.smallVideoIcon {
    align-self: stretch;
    position: relative;
    width: 100%;
    height: 100%;
}

.blankCardIcon {
    object-fit: cover;
}


.smallCards {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: var(--gap-xl);
}

.videoGrid {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--xl2, 40px);
    min-width: 468px;
    max-width: 512px;
}

.container,
.productDisplayHero {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: var(--xl1);
    text-align: left;
    color: var(--charcoal-500);
    padding: var(--xl, 24px) 0;
    max-width: 1128px;
}

.productDisplayHero {
    width: 1440px;
    flex-direction: column;
    justify-content: center;
    padding: 0 var(--padding-xl);
    box-sizing: border-box;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 auto
}

.playButtonWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.playButton {
    width: 100%;
    height: auto;
}

.activeWrapper {
    border: 1px solid var(--primary-500);
}

.smallVideoIconWrapper, .blankCardIconWrapper {
    position: relative;
    aspect-ratio: 16/9;
    max-width: 140px;
}

.productMediaModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    box-shadow: none;
    width: auto;
    overflow: visible;
    background-color: var(--white);
}

.leftCard {
    justify-content: flex-start;
    gap: 42px;
}

.productMediaModal > div {
    top: 0;
    right: 0;
}

@media screen and (max-width: 1200px) {
    .container {
        gap: var(--xl);
    }

    .smallCards {
        flex-wrap: wrap;
        gap: var(--md);
        justify-content: flex-start;
    }

    .smallVideoIconWrapper, .blankCardIconWrapper {
        max-width: 154px;
    }

    .largeVideoIcon.playing {
        transform: scale(1);
    }


}

@media screen and (max-width: 1025px) {

    .buttonGroup {
        flex-direction: column;
    }

    .content {
        gap: var(--lg);
    }

    .videoGrid {
        gap: var(--xl2);
    }

    .container {
        gap: var(--xl);
    }

    .smallVideoIconWrapper, .blankCardIconWrapper {
        max-width: 140px;
    }
}

@media screen and (max-width: 640px) {
    .productMediaModal:not(:has(.wistiaVideoModal)) {
        width: 90%;
    }
    .videoGrid {
        max-width: 100%;
    }

    .mediaModal {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    .largeVideoIcon {
        min-height: 100%;
        width: 100%;
    }
    .wistiaVideoModal {
        min-width: calc(90vw - var(--base) * 2);
        width: 100%;
        height: 100%;
        min-height: 0;
    }

    .smallVideoIconWrapper, .blankCardIconWrapper {
        max-width: 226px;
    }
}

@media screen and (max-width: 450px) {
    .container {
        max-width: 100%;
    }

    .videoGrid {
        min-width: auto;
    }

    .smallCards {
        flex-wrap: nowrap;
    }

    .smallVideoIconWrapper, .blankCardIconWrapper {
        max-height: 70px;
    }

    .buttonGroup {
        flex-wrap: wrap;
    }

    .playButtonWrapper {
        width: 40px;
        height: 40px;
    }
}
