.close1 {
    color: var(--black, #000);
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 153.846% */
}

.close:hover span {
    color: var(--primary-500);
}

.close {
    cursor: pointer;
}

.search {
    line-height: 24px;
    min-width: 50px;
    white-space: nowrap;
    color: var(--black, #000);
    /* Paragraphs */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

.searchButton {
    position: absolute;
    right:0;
}

.closeIcon {
    height: 18px;
    width: 18px;
    position: relative;
    cursor: pointer;
}

.actions,
.close {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.close {
    justify-content: center;
    gap: var(--xs);
    font-size: var(--small-size);
}

.actions {
    align-self: stretch;
    height: 18px;
    justify-content: space-between;
    padding: 0;
    box-sizing: border-box;
    gap: var(--gap-xl);
}

.searchBar,
.searchDynacastBy {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.searchDynacastBy {
    width: 663.8px;
    position: relative;
    line-height: 20px;
    flex-shrink: 0;
    white-space: nowrap;
}

.searchBar {
    align-self: stretch;
    background-color: var(--primary-50);
    border: 1px solid var(--primary-100);
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 0 var(--base);
    gap: var(--gap-xl);
    font-size: var(--small-size);
    color: var(--charcoal-300);
}

.container,
.navbar {
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--base);
    width: 1440px;
    margin: auto;
    padding: var(--base) var(--lg);
}

.mobileContainer {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--base);
    max-width: 1440px;
    width: 100%;
    margin: auto;
    padding-left: 14px;
}

.searchInputBar,
.searchInputBarMobile {
    display: flex;
    width: 100%;
}

.searchInputBar {
    position: relative;
}

.searchInput {
    border-radius: var(--md, 1.5rem);
    border: 1px solid var(--primary-100, #D3E3F1);
    background: var(--primary-50, #F4F8FC);
    height: 28px;
    width: 100%;
    padding-left: 15px;
    padding-right: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.searchInputMobile {
    border-radius: var(--md, 1.5rem);
    border: 1px solid var(--primary-100, #D3E3F1);
    background: var(--primary-50, #F4F8FC);
    height: 2.625rem;
    width: 100%;
    padding-left: 15px;
    padding-right: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--charcoal-500);
}

.searchButtonMobile {
    position: absolute;
    right: 12px;
    top: 68px;
}

.searchTitleMobile {
    color: var(--primary-500);
    font-family: var(--standard-font);
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    padding-top: 14px;
    padding-bottom: 6px;
    padding-left: 10px;
}

.mobileOverlay {
    overflow: hidden; /* Prevents scrollbars from appearing */
}

/* Optional: Hide scrollbars for the inner container if needed */
.container {
    overflow: hidden;
}

.mobileOverlay::-webkit-scrollbar,
.container::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.navbar {
    width: 1440px;
    background-color: var(--white);
    border-bottom: 0.3px solid var(--charcoal-500);
    box-sizing: border-box;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 15.5px var(--lg) var(--padding-mini);
    top: 0;
    z-index: 99;
    position: sticky;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: var(--paragraphs-size);
    color: var(--black);
    font-family: var(--standard-font);
}

.searchIcon,
.chevronDownIcon {
    position: relative;
}

.searchIcon {
    height: 18px;
    width: 18px;
    min-height: 18px;
    overflow: hidden;
    flex-shrink: 0;
    cursor: pointer;
}

.searchBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    transition: transform 0.3s ease, opacity 0.3s ease;
    animation: slideDown 0.3s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    width: 100%;
}

.mobileOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    width: 100%;
}

.searchBarVisible {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.searchOverlayVisible {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 74px;
    right: -100%;
    background-color: #ffffff;
    transition: right 0.3s ease;
    overflow-x: hidden;
    z-index: 1000;
}

.mobileOverlay.searchOverlayVisible {
    right: 0;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 1200px) {
    .searchIcon {
        height: 24px;
        width: 36px;
        border-right: 0.25px solid black;
        padding-right: 12px;
    }
}
@media screen and (max-width: 1376px) {
    .searchDynacastBy {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .mobileContainer {
        padding: 0 var(--md);
    }
}

@media screen and (max-width: 640px) {
    .mobileContainer {
        padding: 0 var(--base);
    }

    .searchInputMobile {
        padding-right: 120px;
    }
}
