/* Root and Card Group Styles */
.root {
    max-width: 1440px;
    margin: var(--xl) auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-45xl);
    background-color: var(--white);
    line-height: normal;
    letter-spacing: normal;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.eyebrow {
    color: var(--charcoal-500, #37353C);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem; /* 125% */
    letter-spacing: 0.1rem;
    text-transform: uppercase;
}

.swiperWrapper {
    width: 100%;
}

.contentWrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
}

    .contentWrapper.hasFilter {
        flex-direction: row;
    }

.customSwiper .swiper-wrapper {
    gap: 1px;
}

.cardPairWrapper {
    display: flex;
    width: auto;
    gap: 1px;
    width: auto;
}

.smallSquareCardGroup {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 1px;
    overflow-x: auto;
    max-width: 100%;
    text-align: left;
    font-size: var(--button-size);
    color: var(--white);
    font-family: var(--standard-font);
}

/* Text Styles */
.title {
    margin: 0;
    padding: 0 2.25rem;
    position: relative;
    align-self: stretch;
    font-size: inherit;
    font-weight: 600;
    line-height: 48px;
    font-family: var(--standard-font);
    background: linear-gradient(50.27deg, #000, #37353c);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.title2 {
    /* Additional styles if needed for title2 */
}

.spaceForAn,
.lineDescriptionOf,
.material {
    position: relative;
    line-height: 24px;
}

.spaceForAn {
    font-size: var(--button-size);
    align-self: stretch;
}

.bodyParagraphCardParent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    text-align: center;
    font-size: var(--h3-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
    align-self: stretch;
}

.materialWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 31px 0 27px;
    text-align: center;
    font-size: var(--h5-size);
}

.link {
    display: inline-block;
    min-width: 93px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    color: var(--primary-500);
}

.prevButton, .nextButton {
    border-radius: var(--br-5xl);
    border: 1px solid var(--charcoal-500);
    padding: var(--padding-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out; /* Smooth transition for color change */
    background: white;
}

    .prevButton:hover, .nextButton:hover {
        background: var(--charcoal-500);
        transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        --chevron-color: white; /* Change color to white on hover */
    }

.pagination {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--base);
    width: auto !important;
}

.navigationWrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-top: var(--base);
    text-align: right;
}

.lastOddPair {
    flex: 2;
}

.lastOddPair.centerOddPair {
    flex: none;
}

.emptyCard {
    width: 359px;
}

.contentWrapper.hasFilter {
    gap: var(--xl);
}


/* Responsive Styles */
@media screen and (max-width: 1439px) {
    .bodyParagraphCardParent,
    .smallSquareCardGroup {
        padding-left: 1rem;
        padding-right: 1rem;
        box-sizing: border-box;
    }
    
    .lastOddPair {
        flex: none;
    }
}


@media screen and (max-width: 1024px) {
    .contentWrapper.hasFilter {
        flex-direction: column;
    }

    .hasFilter .lastOddPair {
        justify-content: center;
    }
}

@media screen and (min-width: 1440px) {
    .hasFilter .lastOddPair {
        justify-content: center;
    }
}

@media screen and (max-width: 1439px) {
    .root {
        gap: var(--md);
        margin: var(--xl2);
        padding: 0;
    }
}

@media screen and (max-width: 768px) {

    .bodyParagraphCardParent {
       gap: var(--md);
    }

    .bodyParagraphCardParent,
    .smallSquareCardGroup {
        padding-left: 0;
        padding-right: 0;
    }

    .bodyParagraphCardParent .title {
        font-size: var(--font-size-12xl);
        line-height: 1.875rem;
        padding: 0;
    }

    .bodyParagraphCardParent .eyebrow {
        line-height: 1.25rem;
    }
    .bodyParagraphCardParent .spaceForAn {
        line-height: 1.5rem;
    }
    .cardPairWrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 640px) {
    .root {
        margin: var(--xl2) var(--base);
    }

    .pagination, .navigationWrapper {
        gap: var(--xs);
    }

}