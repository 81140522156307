.wrapper {
    margin: var(--xl) auto;
    max-width: 1440px;
    padding: var(--xl) 0 var(--xl) var(--2xl);
    background: linear-gradient(81.45deg, #000000 8.04%, #37353C 95.17%) no-repeat calc(100% - var(--2xl)) / 60% 100%, transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.overlay {
    position: absolute;
    top: 0;
    right: var(--2xl);
    bottom: 0;
    background-color: var(--image-overlay-light);
    transition: background-color 0.3s ease-in-out;
    z-index: 1;
    width: 60%;
}

.content {
    color: var(--white);
    gap: var(--2xl);
    display: flex;
    align-items: flex-end;
    padding: var(--xl);
    background-color: var(--white);
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
    gap: var(--2xl);
    justify-content: space-between;
    max-width: 70%;
    z-index: 2;
    position: relative;
}

.content .text {
    display: flex;
    flex-direction: column;
    gap: var(--base);
}

.content .title {
    font-size: var(--h3-size);
    font-weight: 600;
    line-height: 48px;
    margin: 0;
    color: var(--charcoal-500);
}

.content .subtitle {
    font-size: var(--h5-size);
    line-height: 24px;
    margin: 0;
    color: var(--charcoal-300);
}

.ctaGroup {
    display: flex;
    flex-direction: column;
    gap: var(--base);
}

@media screen and (max-width: 1439px) {
    .wrapper {
        margin: var(--xl2);
        padding: var(--xl2) 0;
        width: calc(100% - var(--xl2) - var(--xl2));
    }

    .content {
        padding: var(--md);
        gap: var(--md);
        max-width: 70%;
    }

    .content .title {
        font-size: var(--font-size-12xl);
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        width: 100%;
        max-width: fit-content;
        padding: var(--xl2);
        margin: var(--xl2) 0;
        background: linear-gradient(81.45deg, #000000 8.04%, #37353C 95.17%) no-repeat right / 100% 100%, transparent;
    }

    .overlay {
        width: 100%;
        right: 0;
    }

    .content {
        padding: var(--md) var(--base);
        max-width: 100%;
        flex-direction: column;
        align-items: center;
        gap: var(--md);
    }

    .content .title {
        font-size: var(--font-size-12xl);
        line-height: 30px;
    }

    .text {
        text-align: center;
    }
}

@media screen and (max-width: 640px) {
    .wrapper {
        padding: var(--md) var(--base);
    }
}