.tab {
    text-decoration: none;
    width: 130px;
    color: var(--charcoal-500);
    text-align: center;
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding-2xs) var(--padding-40xl) var(--padding-4xs);
    font-size: var(--font-size-12xl);
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 96.774% */
}

.horizontalTab,
.horizontalTabWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-width: 100%;
}

.horizontalTab {
    cursor: pointer;
    padding: var(--sm) var(--padding-xl) 8px;
    background-color: var(--white);
    box-shadow: var(--shadow-cta);
    border-radius: var(--xs) var(--xs) 0 0;
    flex: 0.9024;
    max-width: 410px;
    flex-direction: row;
    height: 44px;
    color: var(--charcoal-500, #37353C);
    text-align: center;
    padding-bottom: 1rem;
}

.horizontalTab.activeTab {
    border-radius: var(--xs, 0.5rem) var(--xs, 0.5rem) 0rem 0rem;
    border-bottom: 3px solid var(--primary-500, #2273BA);
    background: var(--white, #FFF);
    /* shadow-cta */
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    z-index: 99;
}

.swiper {
    width: 100%;
    padding-left: 0;
    overflow: visible;
}

.horizontalTabWrapper {
    flex: 1;
    flex-direction: row;
    padding: var(--xl) 0 0;
}

.tabsFlexible {
    width: 1230px;
    background-color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    margin: 0 auto;
    font-size: var(--h4-size);
    color: var(--charcoal-500);
    font-family: var(--standard-font);
}

.tabContainer {
    gap: var(--gap-45xl);
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--charcoal-300);
    margin-bottom: 3rem;
}

.resourceTitle {
    color: var(--charcoal-500, #37353C);
    font-size: var(--font-size-12xl);
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 96.774% */
    padding-left: 8rem;
}

@media screen and (max-width: 1440px) {
    .tabContainer{
        margin-left: var(--xl2);
        margin-right: var(--xl2)
    }
}

@media screen and (max-width: 640px) {
    .tabContainer{
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 1024px) {
    .horizontalTabWrapper{
        flex-direction: column;
        padding: 0;
    }
    .tabsFlexible{
        width: 100%;
    }
    .horizontalTab{
        width: 100%;
        max-width: 100%;
    }
}
@media screen and (max-width: 975px) {
    .tabsFlexible {
        flex-wrap: wrap;
    }

    .automotive,
    .automotive1,
    .automotive2,
    .automotive3 {
        font-size: var(--font-size-6xl);
        line-height: 24px;
    }
}

@media screen and (max-width: 450px) {
    .automotive,
    .automotive1,
    .automotive2,
    .automotive3 {
        font-size: var(--font-size-lgi);
        line-height: 18px;
    }

    .horizontalTab {
        flex: 1;
        padding-left: var(--padding-xl);
        padding-right: var(--padding-xl);
        box-sizing: border-box;
    }
}

