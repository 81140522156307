.wrapper {
    max-width: 1440px;
    margin: 0 auto;
}

    .wrapper.darkBackground {
        background: linear-gradient(50deg, #000 8.57%, #37353C 94.33%);
    }


.wrapper.darkBackground .title, .wrapper.darkBackground h3 {
    color: var(--white);
    font-size: 2.4375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem; /* 123.077% */
    text-decoration: none;
}

.wrapper.darkBackground p {
    color: var(--white);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}

    .wrapper.lightBackground h4 {
        color: var(--charcoal-500, #37353C);
    }

    .wrapper.lightBackground p {
        color: var(--charcoal-500, #37353C);
    }

.wrapper.shadow .innerWrap {
    border-radius: 24px;
    box-shadow: var(--shadow-component-light);
}

.shadowImage{
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.15));
    max-height: 393px;
}

.contentCarousel {
    max-width: 42.375rem;
}

.lightBackground .contentImg {
    box-shadow: var(--shadow-component-light);
}

.spotlightCarousel .contentCarousel {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--md);
}

.swiperWrap {
    flex-basis: 60%;
    max-width: 43.75rem;
    position: relative;
}

.innerWrap {
    display: flex;
    gap: 4rem;
    flex-direction: row;
    padding: 3rem 3.62rem;
    align-items: center;
}

.contentImg {
    max-width: 43.75rem;
    width: 100%;
    height: 100%;
    height: 25rem;
    overflow: hidden;
    display: contents;
}

.contentImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.background {
    width: 60%;
    order: 2;
    height: 100%;
    max-height: 388px;
}

.background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {
    gap: var(--lg);
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
}

.content .title {
    font-size: 39px;
    font-weight: 600;
    line-height: 48px;
    margin: 0;
    color: var(--charcoal-500);
    text-align: left;
    align-self: flex-start;
}

.content .subtitle {
    line-height: 24px;
    margin: 0;
    color: white;
    text-align: left;
}

.lightBackground .subtitle {
    color: var(--charcoal-500);
}

.content a {
    margin: 0 !important;
}


.chevronRightSmall,
.chevronLeftLarge,
.chevronRightLarge {
    height: 10px;
    width: 5px;
    position: relative;
    object-fit: contain;
    flex-shrink: 0;
}

.chevronLeftLarge,
.chevronRightLarge {
    height: 18.5px;
    width: 20px;
    overflow: hidden;
}

.navigationWrapper,
.navigation {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    margin-top: 1rem;
}

.contentText {
    color: var(--charcoal-500);
}

.titleCarousel {
    color: var(--white);
    font-size: var(--font-size-12xl);
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 96.774% */
    overflow-wrap: break-word;
}


.wrapper.darkBackground .contentText * {
    color: var(--white);
}

.contentText p {
    margin-top: 20px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
}



.button,
.content,
.contentGroup,
.paginationButton,
.pagination {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.pagination {
    flex-direction: row;
    gap: var(--base);
}

.contentGroup,
.grid,
.container {
    gap: var(--xs);
}

.button,
.content {
    flex-direction: column;
    box-sizing: border-box;
}

.content {
    min-width: 36rem;
}

.button {
    cursor: pointer;
    border: 1px solid var(--primary-500);
    padding: 9px 67px;
    background-color: var(--primary-500);
    width: 250px;
    border-radius: var(--md);
    align-items: center;
    min-width: 250px;
}

.prevButton, .nextButton {
    border-radius: var(--br-5xl);
    border: 1px solid var(--charcoal-500);
    padding: var(--padding-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out; /* Smooth transition for color change */
    background: var(--white);
}

    .prevButton:hover,
    .nextButton:hover,
    .prevButton[disabled],
    .nextButton[disabled] {
        background: var(--charcoal-500);
        --chevron-color: var(--white);
    }

    .prevButton[disabled],
    .nextButton[disabled] {
        pointer-events: none;
    }

.centeredNavigation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navigationWrapper {
    gap: var(--base);
}

.showNavigation {
    display: flex !important;
}

.hideNavigation {
    display: none !important;
}

@media screen and (max-width: 1400px) {
    .titleCarousel {
        max-width: 21rem;
    }

    .content {
        min-width: 0;
        max-width: 30rem;
        width: auto;
    }
}

@media screen and (max-width: 1200px) {
    .wrapper {
        margin: var(--xl2) auto;
        padding: var(--xl2);
    }

    .innerWrap {
        padding: 0;
        margin: 0;
        gap: var(--md)
    }

    .background {
        height: 390px;
    }

    .content {
        flex-direction: column;
        text-align: center;
        top: 0;
        left: 0;
        justify-content: flex-start;
        min-width: 16rem;
    }

    .titleCarousel {
        max-width: 20rem;
    }

    .swiperWrap {
        flex-basis: 70%;
    }

    .prevButton:hover,
    .nextButton:hover {
        background: var(--white);
        --chevron-color: var(--charcoal-500);
    }

    .prevButton[disabled],
    .nextButton[disabled] {
        background: var(--charcoal-500);
        --chevron-color: var(--white);
    }
}

@media screen and (max-width: 1024px) {
    .wrapper {
        flex-direction: column;
        gap: var(--lg);
    }

    .innerWrap {
        flex-direction: column;
    }

    .background {
        height: 300px;
        width: 100%;
    }

    .content {
        width: 100%;
        padding: 0;
        max-width: 100%;
        gap: var(--lg);
    }

    .contentCarousel {
        max-width: none;
    }

    .titleCarousel {
        max-width: 21rem;
    }

    .swiperWrap {
        width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .wrapper {
        padding: var(--base);
    }

    .innerWrap {
        gap: var(--md);
    }

    .content {
        gap: var(--md);
    }

    .navigationWrapper {
        justify-content: flex-start;
        width: 100%;
        max-width: unset;
        position: absolute;
        z-index: 1;
        transition: top 0.2s ease;
    }
}