.errorPage {
    max-width: 1440px;
    margin: 0 auto;
    justify-content: center;
}

.errorWrapper {
    background: var(--charcoal-500);
}

.errorContainer > div,
.errorContainer > div > div {
    height: auto;
}
