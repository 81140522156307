.rightColumnTitle {
    font-size: var(--small-size);
}
.brandmarkIcon {
    width: 46.4px;
    height: 46px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.body,
.title {
    position: relative;
}

.title {
    margin: 0;
    font-size: inherit;
    line-height: 30px;
    font-family: var(--standard-font);
    color: white;
    font-size: var(--font-size-12xl);
    font-family: var(--standard-font);
    font-weight: 500;
    line-height: 30px;
    word-wrap: break-word
}

.body {
    align-self: stretch;
    font-size: var(--small-size);
    line-height: 20px;
    color: white;
    font-size: 13px;
    font-family: var(--standard-font);
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word
}

.input,
.inputContent {
    width: 95%;
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.inputContent {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 22px;
    flex-direction: row;
    align-items: center;
    padding: 1.5px 0;
    box-sizing: border-box;
    font-family: var(--standard-font);
    font-size: var(--button-size);
    color: var(--white);
    min-width: 168px;
}

.input {
    border-radius: 8px;
    border: 1px solid var(--white);
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 15px;
}

.viewAll {
    flex: 1;
    position: relative;
    font-size: var(--button-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--standard-font);
    color: var(--white);
    text-align: center;
    display: inline-block;
    min-width: 57px;
    white-space: nowrap;
}

.chevronRightSmall {
    height: 10px;
    width: 5px;
    position: relative;
    object-fit: contain;
}

.contentGroup {
    width: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--xs);
}

.button,
.rightColumn {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button {
    cursor: pointer;
    border: 1px solid var(--primary-500);
    padding: 11px var(--padding-xl);
    background-color: var(--primary-500);
    width: 250px;
    border-radius: var(--md);
    align-items: center;
    min-width: 250px;
    height: 46px;
}

.rightColumn {
    width: 312px;
    border-right: 1px solid var(--charcoal-300);
    box-sizing: border-box;
    justify-content: center;
    padding: 0 var(--padding-4xl) 0 0;
    gap: var(--base);
    min-width: 21rem;
    max-width: 100%;
}

.linkPrimary {
    text-decoration: none;
    position: relative;
    line-height: 20px;
    color: inherit;
}

.footerLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.logo {
    display: flex;
    flex-direction: column;
    gap: var(--base);
}

.links {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--md);
    color: var(--white, #FFF);
    font-family: var(--standard-font);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
}



.container,
.footer,
.linksGrid {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
}

.linksGrid {
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 var(--5xl);
    box-sizing: border-box;
    min-width: 30rem;
    gap: var(--gap-xl);
    font-size: var(--small-size);
}

.container,
.footer {
    align-items: flex-start;
    justify-content: flex-start;
}

.container {
    flex: 1;
    padding: 0;
    row-gap: 20px;
    text-align: left;
    font-size: var(--h4-size);
    color: var(--white);
    font-family: var(--standard-font);
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
}

.footer {
    width: 100%;
    background-color: var(--charcoal-500);
    border-top: 0.5px solid var(--charcoal-300);
    overflow: hidden;
    padding: var(--lg) var(--xl);
    line-height: normal;
    letter-spacing: normal;
}

.social {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--gap-17xl);
}


.socialFormTechnologies a img {
    height: 100%;
    object-fit: contain;
}

.socialFormTechnologies {
    align-items: flex-start;
    padding: 0 var(--md) 0 90px; 
    border-left: 1px solid var(--charcoal-300);
}

.linkPrimary:hover {
    color: inherit;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.socialIconWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--md);
}

@media screen and (max-width: 1425px) {
    .linksGrid,
    .rightColumn {
        box-sizing: border-box;
    }


    .socialFormTechnologies {
        border-left: none;
    }

}

@media screen and (max-width: 1200px) {
    .linksGrid,
    .rightColumn {
        flex: 1;
        box-sizing: border-box;
    }

    .rightColumn {
        padding: var(--lg) var(--base) var(--md);
        border-right: none;
    }

    .rightColumn::after {
        content: '';
        border-bottom: 0.5px solid var(--charcoal-300);
        padding-top: var(--md);
    }

    .linksGrid {
        min-width: 100%;
        flex-wrap: wrap;
        padding: 0 var(--base) var(--lg);
    }

    .container {
        flex-wrap: wrap;
        padding: 0;
        gap: var(--md);
    }

    .footer {
        padding: 0;
    }

    .links {
        min-width: 145px;
    }

    .footer .input {
        width: 100%;
    }

    .footer .social {
        order: 5;
        width: 100%;
        flex-direction: row;
        padding: var(--base) 0 var(--base) 0;
        border-top: 0.5px solid var(--charcoal-300);
        margin-top: calc(var(--md) * -1);
    }

    .logo {
        flex-direction: row;
        align-items: center;
    }

    .socialIconWrapper {
        display: flex;
        flex-direction: row;
        gap: var(--lg);
    }


}

@media screen and (max-width: 825px) {
    .title {
        font-size: 25px;
        line-height: 24px;
    }

    .rightColumn {
        min-width: 100%;
    }

    .linksGrid,
    .rightColumn {
        flex: 1;
        box-sizing: border-box;
    }

    .footer {
        box-sizing: border-box;
    }

    .socialFormTechnologies .rightColumnTitle {
        margin: auto;
    }

    .footer .social.socialFormTechnologies {
        flex-direction: column;
        align-items: center;
        gap: var(--xs);
    }

    .socialFormTechnologies .socialIconWrapper {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: var(--md, 1.5rem);
    }

}

@media screen and (max-width: 700px) {

    .socialFormTechnologies .socialIconWrapper img {
        width: 6.06019rem;
        height: 1.71875rem;
    }
}

    @media screen and (max-width: 450px) {
        .title {
            font-size: 19px;
            line-height: 18px;
        }

        .footer .social.socialFormTechnologies {
            gap: var(--xs1);
        }

        .linksGrid,
        .rightColumn {
            flex: 1;
            box-sizing: border-box;
        }

        .container {
            flex-wrap: wrap;
            gap: var(--md);
        }

    }




