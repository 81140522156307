.container {
    max-width: 1230px;
    margin: 0 auto;
}

.root {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    line-height: normal;
    letter-spacing: normal;
    margin: 0 auto;
}

.textWrapper {
    margin-top: 2rem;
}

.overview {
    margin: 0;
    align-self: stretch;
    height: 47px;
    position: relative;
    font-family: var(--standard-font);
    display: inline-block;
    flex-shrink: 0;
    color: var(--charcoal-500, #37353C);
    font-size: var(--font-size-12xl);
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 96.774% */
    padding-bottom: 4rem;
}

.content {
    flex-direction: column;
    align-items: center;
    gap: 64px;
    text-align: left;
    font-size: var(--h4-size);
    color: var(--charcoal-primary);
    font-family: var(--standard-font);
}

@media screen and (max-width: 750px) {
    .overview {
        font-size: var(--h5-size);
        line-height: 24px;
    }
}

@media screen and (max-width: 675px) {
    .content {
        gap: 32px;
    }
}

@media screen and (max-width: 450px) {
    .overview {
        font-size: 19px;
        line-height: 18px;
    }

    .content {
        gap: var(--gap-base);
    }
}