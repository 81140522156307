.outerWrapper {
    width: 100%; /* Ensure it is responsive */
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
    margin-top: 2.5rem;
    flex-direction: column;
}

.moduleWrapper {
    max-width: 100%; /* Ensure it is responsive */
    width: 100%;
    margin: var(--xl2) auto;
    display: flex;
    gap: 1rem;
    margin-top: 2.5rem;
}

.innerWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--base);
}

.frameParent {
    width: 100%;
    gap: var(--gap-17xl);
    display: flex;
    flex-direction: column;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--base);
    padding: var(--padding-6xs) var(--padding-xl);
    padding-right: 7rem;
    flex-wrap: wrap; /* Allow wrapping */
    min-height: 50rem;
    padding-top: 0;
    margin-left: var(--padding-37xl);
}

.name {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping for text and image */
    gap: var(--xs);
    color: var(--black, #000);
    font-family: "Roboto Condensed";
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
    width: 100%; /* Full width for wrapping */
}

    .name img {
        width: 1.5rem;
        height: 1.5rem;
    }

    .name h3 {
        font-family: var(--font-roboto-condensed);
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
    }

        .name span li {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
        }

.description {
    width: 100%; /* Ensure full width for description */
    display: flex;
    flex-direction: column;
    gap: var(--base);
}

    .description p {
        color: var(--black, #000);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        word-wrap: break-word; /* Ensure long words break */
        margin: 0;
    }

    .description ul {
        margin: 0;
    }

    .description ul li,
    .description ul li p {
        color: var(--black, #000);
        font-size: 0.8125rem;
        font-weight: 400;
        line-height: 1.25rem;
        margin: 0;
    }

.bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allow wrapping for note and button */
    gap: 1rem;
    margin-bottom: 1rem;
}

    .bottom p {
        margin: 0;
        color: var(--charcoal-500, #37353C);
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        word-wrap: break-word; /* Ensure long words break */
        max-width: 44rem;
        min-width: 22rem;
    }

    .bottom a {
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.02em;
        padding: 0 16px;
    }

    .certification:not(:last-child) {
        border-bottom: 0.5px solid var(--charcoal-200, #BFBEBE);
        background: #FFF;
    }


    @media (max-width: 1200px) {
        .moduleWrapper {
            width: 100%;
            flex-direction: row;
            margin: var(--xl2);
        }

        .certification {
            flex-direction: column;
            align-items: flex-start; /* Align items to start */
        }

        .bottom {
            flex-direction: column; /* Stack items vertically */
            align-items: flex-start; /* Align items to start */
            gap: 1rem; /* Add spacing between elements */
        }
        .innerWrapper{
            padding: 0px;
        }
        .certification:not(:last-child){
            padding-bottom: var(--base);
        }
    }

    @media (max-width: 1024px) {
      .moduleWrapper{
        flex-direction: column;
        box-sizing: content-box;
        margin: var(--xl2) 0;
      }
      .wrapper{
        margin-left: 0;
      }
    }
