.imageIcon {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
}

.imageContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 50%;
    height: 100%;
    position: relative; /* Ensures overlay is positioned relative to the image */
    display: inline-block;
    overflow: hidden;
    max-height: 350px;
}

.roundedCorners .heroSplit  {
    margin: 5rem 4rem;
    box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem;
    padding: 2.5rem;
}

.videoOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--image-overlay); /* Light grey overlay */
    transition: background-color 0.3s ease-in-out; /* Optional transition for overlay */
    z-index: 1; /* Places the overlay above the video but below the content */
}

:lang(de-de) {
    hyphens: auto;
    word-break: break-word;
    overflow-wrap: break-word;
}


.imageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--image-overlay-light); /* Light grey overlay */
    transition: background-color 0.3s ease-in-out; /* Optional transition for overlay */
    z-index: 1; /* Places the overlay above the video but below the content */
}

.backgroundMedia {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    width: 43rem;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0; /* Ensures the video stays behind the content */
}



.lightBackground .currentBreadcrumb,
.lightBackground .currentBreadcrumb:hover {
    color: var(--charcoal-500);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.125rem */
    text-decoration: none;
}

.darkBackground .currentBreadcrumb,
.darkBackground .currentBreadcrumb:hover {
    color: var(--white);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.125rem */
    text-decoration: none;
}

.textAlignLeft.breadcrumb,
.textAlignCenter.breadcrumb {
    display: flex;
    flex-wrap: wrap;
}

.textAlignCenter.breadcrumb {
    justify-content: center;
}

.textAlignLeft.breadcrumb {
    justify-content: left;
}

.textAlignCenter.breadcrumb {
    display: flex;
    justify-content: center;
}

.lightBackground .breadcrumbLink,
.lightBackground .breadcrumbLink:hover {
    color: var(--charcoal-300, #7E7E7E);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
}

.darkBackground .breadcrumbLink,
.darkBackground .breadcrumbLink:hover {
    color: var(--charcoal-300);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-decoration: none;
}

.breadcrumbs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--xs1);
    position: relative;
    line-height: 150%;
    font-weight: 500;
}

.eyebrow {
    position: relative;
    letter-spacing: 0.1em;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: bold;
}

.eyebrow,
.span {
    color: var(--charcoal-500);
}

h1.title {
    font-size: var(--h1-size);
}
h3.title {
    font-size: var(--h3-size);
    flex-direction: column;
}

.textAlignCenter .title {
    text-align: center;
}

.mainHeader .heroSplit {
    border-bottom: 1px solid var(--charcoal-300);
}

.title {    
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    margin: 0;
}

.gradientBackground .titlePart1,
.gradientBackground .titlePart2 {
    font-size: 2.4375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 3rem;
}

.titlePart1 {
    color: var(--white, #FFF);
    font-weight: 600;
    line-height: 61px;
}

.titlePart2 {
    color: var(--primary-500, #2273ba);
    font-weight: 600;
    line-height: 61px;
}

.description {
    position: relative;
    line-height: 24px;
}

.description,
.description * {
    margin: 0;
}

.copy {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: var(--sm);
}

.viewAll {
    flex: 1;
    position: relative;
    font-size: var(--button-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--standard-font);
    color: var(--white);
    text-align: center;
}

.chevronRightSmall {
    height: 10px;
    width: 5px;
    position: relative;
    object-fit: contain;
}

.button,
.content,
.contentGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentGroup {
    align-self: stretch;
    flex-direction: row;
    gap: var(--xs);
}

.isVideo .heroSplit {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;
}

.button,
.content {
    flex-direction: column;
    box-sizing: border-box;
}

.content {
    width: 100%;
    max-width: 664px;
    gap: var(--md);
    max-width: 100%;
    font-size: var(--button-size);
    color: var(--white);
    width: 664px;
}

.split5050 .textAlignCenter .content {
    padding-left: 0;
}

.split5050 .contentWrapper {
    width: 50%;
}

.heroSplit {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contentContainer,
.heroSplit {
    display: flex;
    align-items: center;
    justify-content: center;
}

.alignRight .contentWrapper {
    padding: var(--lg) .5rem 0 var(--2xl);
    height: 100%;
    width: 50%;
}

.heroSplit .contentContainer {
    padding-top: 0px;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1; /* Places the content above the video */
    height: 100%;
    padding: var(--lg) 0;
    gap: var(--sm);
}

.contentContainer {
    flex: 1;
    flex-direction: column;
    padding-top: 6rem;
    box-sizing: border-box;
    min-width: 447px;
    max-width: 100%;
}

.imageLogo {
    max-height: 3.5rem;
}

.container {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0;
    gap: 104px;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    min-height: 21.875rem;
    margin-bottom: 2rem;
    height: 348px;
    max-width: 1440px;
}

.container.split5050 {
    margin-bottom: 0rem;
}

.container.increased {
    height: 32.5rem;
}

.heroSplit {
    display: flex;
    background-color: var(--black);
    flex-direction: row;
    flex-wrap: wrap;
    line-height: normal;
    letter-spacing: normal;
    font-size: var(--breadcrumbs-size);
    color: var(--charcoal-200);
    font-family: var(--standard-font);
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;
    gap: var(--2xl);
}

.split5050 .heroSplit {
    gap: 0;
}

.alignLeft .contentContainer,
.alignRight .contentContainer {
    height: auto;
}

.alignLeft .contentContainer {
    padding-right: var(--2xl);
    padding-left: var(--2xl);
}

.imageIcon {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.alignLeft .imageContainer {
    order: 0;

}

.alignRight .imageContainer {
    order: 1;
}

.justifyContentLeft {
    justify-content: left;
}

.justifyContentCenter {
    justify-content: center;
}

.textAlignLeft {
    text-align: left;
    align-items: flex-start;
}

.textAlignCenter {
    text-align: center;
    align-items: center;
}

.textAlignRight {
    text-align: right;
    align-items: flex-end;
    padding-right: var(--2xl);
}
.lightBackground {
    background-color: var(--white);
}

.lightBackground .breadcrumbs,
.lightBackground .titlePart1,
.lightBackground .description {
    color: var(--charcoal-500) !important;
}

.gradientBackground {
    background: var(--gradient-charcoal, linear-gradient(224deg, #37353C 4.12%, #000 95.88%));
}

.titleContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* Optional: Adds space between spans */
}

@media screen and (max-width: 1439px) {
    h1.title,
    .titlePart1,
    .titlePart2 {
        font-size: var(--h2-size);
    }

    .alignRight .contentWrapper {
        padding-right: var(--lg);
    }
}

@media screen and (max-width: 1200px) {
    .container {
        min-height: 18rem;
    }

    h1.title {
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        font-size: var(--h3-size);
    }

    h1.title,
    .titlePart1,
    .titlePart2 {
        font-size: var(--h3-size);
    }

    .heroSplit {
        gap: var(--xl);
    }

    .contentContainer {
        min-width: 100%;
    }
    .titleContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
    }

    .imageContainer {
        display: none;
    }

    .alignLeft .contentContainer,
    .alignRight .contentContainer {
        padding-right: 0;
        padding-left: 0;
    }

    .alignRight .contentWrapper {
        padding-right: 0;
    }

    .textAlignLeft {
        text-align: center;
        align-items: center;
        padding: 0;
    }

    .textAlignCenter {
        text-align: center;
        align-items: center;
        padding: 0;
    }

    .textAlignRight {
        text-align: center;
        align-items: center;
        padding: 0;
    }

    .contentWrapper {
        width: 100%;
    }

    .split5050 .heroSplit .contentWrapper {
        padding: var(--md);
        width: 100%;
        
    }

    .alignRight .contentWrapper {
        padding-left: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: 100%;
        width: 100%;
    }

    .container.increased.minHeight {
        height: auto;
        min-height: auto;
        padding-top: var(--2xl);
        padding-bottom: var(--2xl);
    }

    .textAlignLeft.breadcrumb,
    .textAlignCenter.breadcrumb {
        padding-top: 0;
        margin-bottom: 0;
        justify-content: center;
    }
}

@media screen and (max-width: 640px) {
    .imageContainer {
        display: none;
    }

    .title {
        flex-wrap: wrap;
        justify-content: center;
    }

    .contentContainer {
        min-width: 100%;
        margin-left: inherit;
    }

    .titlePart1 {
        margin-right: 0;
    }

    .titlePart1,
    .titlePart2 {
        font-size: var(--font-size-12xl);
        padding-right: 0;
        width: fit-content;
    }

    .alignRight .heroSplit {
        justify-content: start;
    }

    .breadcrumb {
        display: none!important;
    }

    .container.split5050 {
        height: 100%;
    }

    .split5050.alignLeft .content,
    .split5050.alignRight .content {
        padding-left: 0;
        align-items: start;
    }

    .split5050.alignLeft .copy,
    .split5050.alignRight .copy {
        align-items: start;
    }

    .split5050 .imageContainer {
        display: block;
    }

    .split5050 .heroSplit {
        flex-direction: row;
        height: 100%;
        justify-content: start;
        
    }

    .split5050 .heroSplit .textAlignLeft,
    .split5050 .heroSplit .textAlignRight {
        text-align: left;
        align-items: start;
    }

    .split5050 .heroSplit .imageContainer {
        flex: auto;
        order: 0;
    }

    .split5050 .heroSplit .contentWrapper .title,
    .split5050 .heroSplit .contentWrapper .titlePart1,
    .split5050 .heroSplit .contentWrapper .titlePart2 {
        font-size: var(--font-size-12xl);
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 96.774% */
        width: 100%;
    }
    
    .alignRight .contentWrapper {
        padding-left: var(--xl2);
        padding-right: var(--xl2);
        padding-top: 0;
        padding-bottom: 0;
        height: 100%;
        width: 100%;
    }

    .container.increased {
        height: auto;
    }

    .titlePart1,
    .titlePart2 {
        font-size: var(--font-size-12xl);
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 96.774% */
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .content,
    .contentContainer {
        box-sizing: border-box;
    }

    .titleContainer
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding: 0px;
        gap: 0;
    }

    .description{
        max-width: 348px;
        margin: 0;
    }

    .heroSplit {
        gap: var(--xl);
    }

    .copy{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: var(--font-size-xs);
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }

    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
        flex: none;
        order: 1;
        flex-grow: 0;
    }


    .alignRight .contentWrapper, .contentWrapper {
        padding: var(--2xl) var(--md);
    }

    .isVideo .heroSplit .contentWrapper{
        width: 100%;
    }

    .roundedCorners .heroSplit {
        margin: var(--xl2) var(--base);
        padding: var(--base);
    }

    .textAlignCenter .title {
        text-align: center;
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .container.increased.minHeight {
        padding-top: 0;
        padding-bottom: 0;
    }

    .isVideo .heroSplit {
        padding-left: 0px;
        padding-right: 0px;
    }
    .heroSplit.showMobileMedia {
        gap: var(--md);
    }

    .showMobileMedia .imageContainer {
        display: block;
        margin: 0 var(--base) var(--lg);
    }

    .showMobileMedia .contentWrapper {
        padding-bottom: 0;
    }

}