.viewAll {
    flex: 1;
    position: relative;
    font-size: var(--button-size);
    line-height: 20px;
    font-weight: 500;
    font-family: var(--standard-font);
    color: var(--charcoal-500);
    text-align: center;
}

.chevronRightSmall {
    height: 10px;
    width: 5px;
    position: relative;
    object-fit: contain;
    margin-left: 5px;
}

.button,
.contentGroup {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentGroup {
    align-self: stretch;
    flex-direction: row;
    gap: var(--xs);
}

.button {
    cursor: pointer;
    border: 1px solid var(--charcoal-500);
    /*padding: 0.75rem 2rem;*/
    background-color: transparent;
    border-radius: var(--md);
    box-sizing: border-box;
    width: 188px; /* Explicit width */
    height: 48px; /* Explicit height */
    text-decoration: none; /* Ensure no underline */
    text-align: center; /* Center the text */
    transition: 'background-color 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out';
    font-size: 1rem;
}

.button:hover {
    border-radius: var(--md, 24px);
    border: 1px solid var(--charcoal-500, #00A19B);
    background: rgba(255, 255, 255, 0.01);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    text-decoration: none; /* Ensure no underline on hover */
    transition: 'background-color 300ms ease-out, color 300ms ease-out, box-shadow 300ms ease-out';
}

.button:focus,
.button:active {
    text-decoration: none; /* Ensure no underline on focus or active states */
}

.button,
.button * {
    text-decoration: none; /* Ensure no underline for child elements */
}

@media screen and (max-width: 1200px) {
    .button {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.25rem; /* 125% */
        padding: 0.5rem 1rem;
    }
}
