.CompanyCareerContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--xl) auto;
    max-width: 1440px;
    width: 100%;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: var(--standard-font);
    color: var(--charcoal-500);
    gap: var(--lg);
}

.CompanyCareerTitle{
    font-size: var( --h3-size);
    line-height: 3rem;
    font-weight: 600;
}
.CompanyCareerBody{
    font-weight: 400;
    line-height: 1.5rem;
}

.CompanyCareerLogoGroups{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 32px 104px 0px;
    gap: var(--xl);
}


.CompanyCareerLogoGroup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: var(--gap-21xl);
    margin: 0 auto;

}

.CompanyCareerLogoGroup_Link{
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.625rem;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-decoration: none;
}

.CompanyCareerLogoGroup_Link1{
    color: #2273BA;
}

.CompanyCareerLogoGroup_Link2{
    color: #D9272E;
}


.CompanyCareerLogoGroup_Link3{
    color: #5B5397;
}
.logoTeam{
    height: 4rem;
    width: 4rem;
}

@media screen and (max-width: 1439px)  {
    .CompanyCareerContainer{
        margin: var(--xl2);
        gap: var(--md);
        max-width: 100%;
        width: auto;
    }

    .CompanyCareerLogoGroups {
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: var(--lg);
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 1024px)  {
    .CompanyCareerTitle{
        font-size: var( --h3-size);
        line-height: 1.875rem;
        font-weight: 600;
    }

    .CompanyCareerBody{
        font-size: 1rem;
        line-height: 24px;
        text-align: center;
    }
    .CompanyCareerLogoGroup{
        gap: var(--base)
    }
}


@media screen and (max-width: 640px)   {
    .CompanyCareerContainer{
        margin: var(--xl2) var(--base);
        gap: var(--md);
    }

    .CompanyCareerTitle{
        font-size: var(--font-size-12xl);;
        line-height: 1.875rem;
    }

    .CompanyCareerBody{
        font-size: 1rem;
        line-height: 24px;
        text-align: center;
    }
    
    .CompanyCareerLogoGroups{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: var(--lg);
    }
}

