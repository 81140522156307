.overview {
    margin: 0;
    align-self: stretch;
    height: 47px;
    position: relative;
    font-family: var(--standard-font);
    display: inline-block;
    flex-shrink: 0;
    color: var(--charcoal-500, #37353C);
    font-size: var(--font-size-12xl);
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; /* 96.774% */
}

.cardGrid,
.content {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
}

.cardGrid {
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: var(--md);
}

.content {
    flex-direction: column;
    align-items: center;
    gap: 64px;
    text-align: left;
    font-size: var(--h4-size);
    color: var(--charcoal-primary);
    font-family: var(--standard-font);
}

.noGap {
    gap: 0;
}

.blankLine,
.loremIpsumDolor {
    margin: 0;
}

.loremIpsum,
.loremIpsum1,
.loremIpusm {
    margin-bottom: 0;
}

.loremIpusmLoremIpsumLorem {
    margin: 0;
    font-family: var(--standard-font);
    font-size: inherit;
    padding-left: 21px;
}

.blankLine1,
.variusDignissimRisus,
.vestibulumTristiqueEget {
    margin: 0;
}

.loremIpsumDolorContainer {
    align-self: stretch;
    position: relative;
    font-size: var(--paragraphs-size);
    line-height: 24px;
    font-family: var(--standard-font);
    color: var(--charcoal-primary);
    text-align: left;
}

.root {
    width: 1230px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-5xl);
    line-height: normal;
    letter-spacing: normal;
    margin: 0 auto;
}

@media screen and (max-width: 1200px) {
    .cardGrid, .overview{
        display: flex;
        justify-content: center;
    }
    .content{
        gap: var( --gap-17xl)
    }
}

@media screen and (max-width: 750px) {
    .overview {
        font-size: var(--h5-size);
        line-height: 24px;
    }
}

@media screen and (max-width: 675px) {
    .content {
        gap: var( --gap-13xl);
    }
}

@media screen and (max-width: 450px) {
    .overview {
        font-size: 19px;
        line-height: 18px;
        align-items: center;
    }

    .content {
        gap: var(--gap-base);
    }
}
