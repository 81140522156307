/* General Styles */

.navWrap {
    position: fixed;
    background: white;
    width: 100%;
    z-index: 99999;
    border-bottom: 0.3px solid var(--charcoal-500);
}

.logoDark {
    display: flex;
    justify-content: center;
    height: 71px;
    width: 193.9px;
    overflow: hidden;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: var(--base);
    box-sizing: border-box;
}

.logoDark img {
    align-self: stretch;
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 55px;
}

.chevronDownIcon {
    position: relative;
}

.chevronDownIcon {
    height: 3px;
    width: 6px;
}

.locations,
.requestAQuote {
    text-decoration: none;
    position: relative;
    color: inherit;
    display: inline-block;
}

.locations {
    letter-spacing: 0.02em;
    color: var(--charcoal-500);
    min-width: 53px;
}


.requestAQuote {
    letter-spacing: 0.02em;
    font-weight: 500;
    min-width: 93px;
    white-space: nowrap;
    color: var(--charcoal-500);
    cursor: pointer;
}

.backButton {
    color: var(--charcoal-500);
    text-decoration: none;
    padding-top: 1.5rem;
    cursor: pointer;
    font-size: 18px;
}

.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--xs1);
}

.top {
    align-self: flex-end;
    justify-content: space-between;
    padding: 0 var(--base);
    gap: var(--md);
}

.bottom {
    display: flex;
    flex-direction: row;
    align-items: center;

}


.bottom {
    justify-content: flex-start;
    gap: var(--xs);
    text-align: center;
}

/* Container Styles */
.container,
.sideNav {
    display: flex;
    align-items: flex-end;
}

.container {
    flex: 1;
    text-align: left;
    font-size: var(--h4-size);
    color: var(--white);
    font-family: var(--standard-font);
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box;
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    gap: var(--xl);
    min-height: 40px; /* Added min-height */
    padding-top: var(--base);
    padding-right: 0;
    
}

.sideNav {
    width: 219px;
    flex-direction: column;
    justify-content: center;
    padding-bottom: var(--base);
    box-sizing: border-box;
    gap: var(--base);
    text-align: left;
    font-size: var(--font-size-xs);
}

.span {
    line-height: 24px;
    white-space: pre-wrap;
}

.ctaTxt,
.cta {
    width: 100%;
}

.cta {
    width: 6.063rem;
    position: relative;
    display: flex;
    align-items: center;
}

.hamburgerMenu {
    display: none;
}

@media screen and (max-width: 1439px) {
    .container {
        gap: var(--lg);
    }
}


@media screen and (max-width: 1200px) {
    .sideNav {
        display: none;
    }  
    .container {
        justify-content: space-between;
        z-index: 1000;
        padding-right: var(--md);
        gap: var(--md);
    }
    .hamburgerMenu {
        cursor: pointer;
        align-self: center;
        display: flex;
        gap: 12px;
    }

    .closeMenuIcon,
    .hamburgerMenuIcon {
        padding-top: 6px;
        width: 24px;
    }

    .closeMenuIcon {
        height: 31px;
        margin-top: 2px;
    }

    .links {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .container {
        gap: var(--md);
        height: 72px;
        padding: 16px 24px;
    }

    .logoDark {
        height: auto;
        padding-bottom: 0px;
    }

    .logoDark img {
        height: 40px;
    }
}

@media screen and (max-width: 360px) {
    .container {
        gap: var(--sm);
    }
}

