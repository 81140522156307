.navigationContainer {
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    max-width: 1440px;
    width: 100%;
    height: 6.25rem;
    margin: auto;
    z-index: 10;
}

.container {
    position: relative;
    max-width: 78.5rem;
    margin: auto;
    margin-bottom: 1rem;
}

.timelineContainer {
    margin: var(--xl) 0;
}

.titleWrapper {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.titleAlt {
    color: var(--brand-primary-500);
}

.title,
.titleAlt {
    font-weight: 600;
    font-size: 2.4375rem;
    margin: 0;
}

.eyebrow {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-transform: uppercase;
    margin: 0;
}

.headerWrapper {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: .75rem;
    max-width: 1440px;
    margin: 3.125rem auto;
}

.description:not(.wrapper > .description) {
    font-size: 1rem;
    font-weight: 400;
    margin: 0 auto;
    max-width: 60%;
}

.prevButton,
.nextButton {
    width: 46px;
    height: 46px;
    border-radius: var(--br-5xl);
    border: 1px solid var(--charcoal-500);
    padding: var(--padding-sm-8) var(--padding-xs);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease-in-out; /* Smooth transition for color change */
    background: white;
}

.prevButton:hover,
.nextButton:hover {
    background: var(--charcoal-500);
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    --chevron-color: white; /* Change color to white on hover */
}

.navigation {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    top: 50px;
    border-top: 1px solid var(--black);
    border-bottom: 1px solid var(--black);
}

.wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wrapper > .description {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: start;
    width: 12.5rem;
    margin-top: var(--xs);
    color: var(--black);
}

.chevronRightLarge {
    height: 1.1563rem;
    width: 1.25rem;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.prevButton,
.nextButton {
    margin: auto 0;
    height: 2.875rem;
    width: 2.875rem;
    border-radius: 50%;
    border: 1px solid var(--black);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    padding: 0;
    z-index: 10;
    cursor: pointer;
}

.prevButton {
    margin-left: 1.4375rem;
}
.nextButton {
    margin-right: 1.4375rem;
}

.slide {
    box-sizing: border-box;
    justify-content: left;
    flex: 0 0 auto;
}

.slide img {
    width: 12.5rem;
    height: 12.5rem;
    position: "absolute";
    margin-top: 8.125rem;
}

.down img {
    margin-top: 26.875rem;
}

.longLine {
    position: absolute;
    height: 20.625rem;
    border-right: 1px solid var(--charcoal-300);
    top: 6.25rem;
}

.shortLine {
    position: absolute;
    height: 1.875rem;
    border-right: 1px solid var(--charcoal-300);
    top: 6.25rem;
}

.year {
    position: absolute;
    top: 3.125rem;
    color: var(--primary-500);
    display: flex;
    font-size: 1.5625rem;
    left: 50%;
    transform: translate(-50%, -50%);
}

.yearWrapper {
    width: calc(100% + 194px);
    height: 6.25rem;
    position: absolute;
    top: 0px;
    border-bottom: 1px solid var(--black);
    border-top: 1px solid var(--black);
}
.buttonWrapper {
    display: flex;
    align-items: center;
    background-color: var(--white);
}
@media screen and (max-width: 1200px) {
    .timelineContainer {
        margin: var(--xl2) 0;
    }
}

@media screen and (max-width: 750px) {
    .headerWrapper {
        margin-left: var(--base);
        margin-right: var(--base);
    }
}
@media screen and (max-width: 640px) {
    .description:not(.wrapper > .description) {
       max-width: 100%;
    }
}