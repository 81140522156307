.icon,
.backgroundImage {
    position: absolute;
    margin: 0 !important;
}

.backgroundImage {
    width: 100%;
    height: 37.663rem;
    margin: 0 !important;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.icon {
    height: 4rem;
    width: 4rem;
    top: -20px;
    left: calc(50% - 32px);
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.content,
.verticalCard {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content {
    justify-content: flex-start;
    gap: var(--base);
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    margin-bottom: var(--2xl);
    margin-left: var(--base);
    margin-right: var(--base);
}

.description {
    align-self: stretch;
    position: relative;
    letter-spacing: 0.1em;
    line-height: 1.25rem;
    text-transform: uppercase;
    transition: transform 0.3s ease-in-out;
    height: 0px;
    word-wrap: break-word;
}

.vertical-carousel {
    width: 100%;
}

.verticalCard {
    flex-direction: column;
    width: 248px;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: flex-end;
    /* padding: var(--padding-45xl) var(--padding-14xl) var(--padding-45xl) var(--padding-16xl); */
    box-sizing: border-box;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    font-size: var(--button-size);
    color: var(--white);
    font-family: var(--standard-font);
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
}


    .verticalCard:hover {
        box-shadow: 0px 24px 60px 0px rgba(0, 0, 0, 0.20);
    }

.slogan {
    display: none;
    margin-top: 5px;
    font-weight: normal;
    transition: opacity 0.3s ease-in-out;
    align-self: stretch;
    transition: transform 0.3s ease-in-out;
}

.verticalCard:hover .backgroundImage {
    transform: scale(1.1); /* Zoom in the background image */
}

.verticalCard:hover .content {
    transform: translateY(-40px); /* Move the content up by 16px */
}

.verticalCard:hover .icon,
.verticalCard:hover .description {
    transform: translateY(-10px); /* Move the icon and description up by 16px */
}

.verticalCard:hover .slogan {
    display: block;
    opacity: 1;
    transform: translateY(-10px); /* Move the slogan up by 16px */
}

.cardTeamWrapper{
    border-right: 1px solid var(--charcoal-200, #BFBEBE);
    color: white;
}
.cardTeamWrapper .buttonWrapper .button {
    padding: 0.5rem 2rem !important;
}

    .cardTeamWrapper .slogan {
        height: 40px;
    }

    .cardTeamWrapper .description {
        font-size: var(--font-size-12xl);
        font-weight: 500;
        line-height: 1.875rem;
        margin-bottom: 55px;
    }
.cardTeamWrapper{
    width: 17.1875rem;
    height: 25rem;
}
.cardTeamWrapper.verticalCard{
    padding: var(--base);
    text-align: start;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 48.32%, var(--black) 97.69%);
}
.cardTeamWrapper .slogan{
    display: block;
    z-index: 2;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.25rem;
}

.cardTeamWrapper .backgroundImage {
    width: 17.1875rem;
    height: 25rem;
    bottom: 0rem;
    left: 0rem;
    object-fit: cover;
    z-index: -1;
}

    .cardTeamWrapper .content {
        margin-bottom: .5rem;
        margin-left: 0;
        margin-right: 0;
    }

@media screen and (max-width: 390px) {
    .verticalCard {
        width: 100%;
        max-width: 14rem;
    }
}

@media screen and (max-width: 640px) {
    .verticalCard:not(.verticalCard.cardTeamWrapper) {
        width: 160px;
    }
    .backgroundImage:not(.cardTeamWrapper .backgroundImage) {
        height: 385px;
    }
}